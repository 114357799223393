var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.spinning } },
    [
      _c(
        "div",
        { staticClass: "my-task", style: _vm.backCss },
        [
          _vm.taskList && _vm.taskList.length
            ? _c(
                "div",
                {
                  staticClass: "left",
                  staticStyle: { display: "flex", "flex-direction": "column" },
                },
                [
                  _vm.taskList.findIndex(
                    (item) => item.taskId == _vm.taskDetail.taskId
                  )
                    ? _c("a-icon", {
                        style: _vm.isDesktop()
                          ? "font-size:30px"
                          : "font-size:15px",
                        attrs: { type: "left-circle", theme: "filled" },
                        on: { click: _vm.prev },
                      })
                    : _vm._e(),
                  _vm.taskList.findIndex(
                    (item) => item.taskId == _vm.taskDetail.taskId
                  )
                    ? _c(
                        "span",
                        {
                          style: _vm.isDesktop()
                            ? "font-size:25px"
                            : "font-size:12px",
                        },
                        [_vm._v(_vm._s(_vm.taskDetail.chapterSequenceNo - 1))]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.taskList && _vm.taskList.length
            ? _c(
                "div",
                {
                  staticClass: "right",
                  staticStyle: { display: "flex", "flex-direction": "column" },
                },
                [
                  _vm.taskList.findIndex(
                    (item) => item.taskId == _vm.taskDetail.taskId
                  ) !=
                  _vm.taskList.length - 1
                    ? _c("a-icon", {
                        style: _vm.isDesktop()
                          ? "font-size:30px"
                          : "font-size:15px",
                        attrs: { type: "right-circle", theme: "filled" },
                        on: { click: _vm.next },
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      style: _vm.isDesktop()
                        ? "font-size:25px"
                        : "font-size:12px",
                    },
                    [_vm._v(_vm._s(_vm.taskDetail.chapterSequenceNo - 0 + 1))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-row",
            [
              _c(
                "a-breadcrumb",
                { attrs: { separator: ">" } },
                [
                  _c(
                    "a-breadcrumb-item",
                    { staticStyle: { "font-size": "16px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.skip },
                        },
                        [_vm._v("我的任务")]
                      ),
                    ]
                  ),
                  _c(
                    "a-breadcrumb-item",
                    { staticStyle: { "font-size": "16px" } },
                    [
                      _c("span", { staticStyle: { cursor: "pointer" } }, [
                        _vm._v("上传完成稿"),
                      ]),
                    ]
                  ),
                  _c(
                    "a-breadcrumb-item",
                    {
                      staticStyle: { "font-size": "16px" },
                      attrs: { href: "" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.taskDetail.chapterSequenceNo
                              ? _vm.taskDetail.showName +
                                  " - " +
                                  _vm.taskDetail.chapterSequenceNo +
                                  "-" +
                                  _vm.taskDetail.stageName
                              : _vm.taskDetail.showName +
                                  "-" +
                                  _vm.taskDetail.stageName
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { staticClass: "content" },
            [
              _c("a-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "left-button-list" },
                  [
                    _c(
                      "a-button-group",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("a-button", {
                          style:
                            _vm.viewType == "list"
                              ? "width:45px;z-index:9"
                              : "width:45px",
                          attrs: {
                            icon: "unordered-list",
                            type: _vm.viewType == "list" ? "primary" : "",
                            ghost: _vm.viewType == "list" ? true : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeViewType("list")
                            },
                          },
                        }),
                        _c(
                          "a-button",
                          {
                            style:
                              _vm.viewType == "widthView"
                                ? "width:45px;z-index:9"
                                : "width:45px",
                            attrs: {
                              type:
                                _vm.viewType == "widthView" ? "primary" : "",
                              ghost: _vm.viewType == "widthView" ? true : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeViewType("widthView")
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-dengkuanfenbu icon",
                              style:
                                _vm.viewType == "widthView"
                                  ? `color:${this.color} !important`
                                  : "color:black !important;",
                            }),
                          ]
                        ),
                        _c(
                          "a-button",
                          {
                            style:
                              _vm.viewType == "heightView"
                                ? "width:45px;z-index:9"
                                : "width:45px",
                            attrs: {
                              type:
                                _vm.viewType == "heightView" ? "primary" : "",
                              ghost:
                                _vm.viewType == "heightView" ? true : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeViewType("heightView")
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-denggaofenbu icon",
                              style:
                                _vm.viewType == "heightView"
                                  ? `color:${this.color} !important`
                                  : "color:black !important;",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("a-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "right-button-list" },
                  [
                    ((_vm.taskDetail.taskStatus != "等待" &&
                      _vm.taskDetail.taskStatus != "驳回") ||
                      (_vm.taskDetail.taskStatus == "驳回" &&
                        _vm.taskDetail.rejectTaskId)) &&
                    _vm.taskDetail.applyReview
                      ? _c(
                          "a-dropdown",
                          { attrs: { transitionName: "", trigger: ["click"] } },
                          [
                            _c(
                              "a-button",
                              {
                                staticClass: "mr-3 ant-btn",
                                on: {
                                  mouseover: function ($event) {
                                    _vm.checkColor = _vm.color
                                  },
                                  mouseout: function ($event) {
                                    _vm.checkColor = "rgba(0, 0, 0, 0.45)"
                                  },
                                },
                              },
                              [
                                _c("checklist", {
                                  staticStyle: {
                                    position: "relative",
                                    top: "2px",
                                    left: "3px",
                                  },
                                  attrs: {
                                    theme: "outline",
                                    size: "21",
                                    fill: _vm.checkColor,
                                    strokeWidth: 3,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "0",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToFatchStraw(true)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                加载锁定文件\n              "
                                    ),
                                  ]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToFatchStraw(false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                不加载锁定文件\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("actions-tasks-btn", {
                      attrs: {
                        propsParams: _vm.taskDetail,
                        loadData: _vm.reloadData,
                        taskList: _vm.taskDetail.taskList,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                {
                  staticClass: "left-content",
                  attrs: { span: _vm.visible ? 15 : 24 },
                },
                [
                  _c("div", { staticClass: "left-content-button-list" }, [
                    _c(
                      "div",
                      [
                        _vm.taskDetail.applyReview &&
                        (_vm.taskDetail.stageName == "制作" ||
                          _vm.taskDetail.stageName == "制作修改" ||
                          _vm.taskDetail.stageName.indexOf("建模") > -1 ||
                          _vm.taskDetail.stageName.indexOf("精草") > -1 ||
                          _vm.taskDetail.stageName.indexOf("勾线") > -1 ||
                          _vm.taskDetail.stageName.indexOf("上色") > -1 ||
                          _vm.taskDetail.stageName.indexOf("后期") > -1) &&
                        (_vm.taskDetail.taskStatus == "可开始" ||
                          (_vm.taskDetail.taskStatus == "驳回" &&
                            _vm.taskDetail.rejectTaskId))
                          ? _c("defaultSizeUpload", {
                              ref: "defaultSizeUpload",
                              attrs: {
                                btnText: "上传完成稿",
                                upType: _vm.defaultType,
                                taskDetail: _vm.taskDetail,
                                btnType: "finish",
                                sizeList: _vm.sizeList,
                                loadData: _vm.getRightTableData,
                                showFileList: _vm.rightDataSource,
                                saveUrl: "/finish/manuscript",
                              },
                              on: {
                                startProgress: _vm.startProgress,
                                changeProgress: _vm.changeProgress,
                                changeTransProgress: _vm.changeTransProgress,
                                endProgress: _vm.endProgress,
                                replaceImg: _vm.replaceImg,
                              },
                            })
                          : _vm._e(),
                        _vm.taskDetail.applyReview &&
                        _vm.taskDetail.departName &&
                        _vm.taskDetail.departName.indexOf("日语部") > -1 &&
                        (_vm.taskDetail.stageName == "制作" ||
                          _vm.taskDetail.stageName == "制作修改" ||
                          _vm.taskDetail.stageName == "建模" ||
                          _vm.taskDetail.stageName == "精草" ||
                          _vm.taskDetail.stageName == "勾线" ||
                          _vm.taskDetail.stageName == "上色" ||
                          _vm.taskDetail.stageName == "后期") &&
                        (_vm.taskDetail.taskStatus == "可开始" ||
                          (_vm.taskDetail.taskStatus == "驳回" &&
                            _vm.taskDetail.rejectTaskId))
                          ? _c("defaultSizeUpload", {
                              ref: "defaultSizeUploadjpg",
                              attrs: {
                                btnText: "上传切片",
                                upType: _vm.defaultType,
                                taskDetail: _vm.taskDetail,
                                sectionTotalSize: _vm.sectionTotalSize,
                                btnType: "finish",
                                sizeList: _vm.sizeList,
                                loadData: _vm.getRightTableData,
                                showFileList: _vm.rightDataSource,
                                saveUrl: "/finish/manuscript",
                              },
                              on: {
                                startProgress: _vm.startProgress,
                                changeProgress: _vm.changeProgress,
                                changeTransProgress: _vm.changeTransProgress,
                                endProgress: _vm.endProgress,
                                replaceImg: _vm.replaceImg,
                              },
                            })
                          : _vm._e(),
                        _vm.taskDetail.applyReview &&
                        _vm.taskDetail.departName &&
                        _vm.taskDetail.departName.indexOf("日语部") > -1 &&
                        (_vm.taskDetail.stageName == "制作" ||
                          _vm.taskDetail.stageName == "制作修改" ||
                          _vm.taskDetail.stageName == "建模" ||
                          _vm.taskDetail.stageName == "精草" ||
                          _vm.taskDetail.stageName == "勾线" ||
                          _vm.taskDetail.stageName == "上色" ||
                          _vm.taskDetail.stageName == "后期")
                          ? _c("defaultSizeUploadTmb", {
                              ref: "defaultSizeUploadTmb",
                              attrs: {
                                btnText: "上传tmb",
                                upType: _vm.defaultType,
                                taskDetail: _vm.taskDetail,
                                btnType: "finish",
                                sizeList: _vm.sizeList,
                                loadData: _vm.getRightTableData,
                                showFileList: _vm.rightDataSource,
                                saveUrl: "/finish/manuscript",
                              },
                              on: {
                                startProgress: _vm.startProgress,
                                changeProgress: _vm.changeProgress,
                                changeTransProgress: _vm.changeTransProgress,
                                endProgress: _vm.endProgress,
                                replaceImg: _vm.replaceImg,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    !_vm.visible
                      ? _c(
                          "div",
                          [
                            _vm.checkPermission("uploadSubmit:lock")
                              ? _c(
                                  "a-button",
                                  {
                                    staticClass: "mr-3",
                                    attrs: {
                                      disabled:
                                        !_vm.selectedJpgRowKeys.length ||
                                        (_vm.selectionJpgRows.length &&
                                          _vm.selectionJpgRows.every(
                                            (item) => item.wasLock
                                          )) ||
                                        _vm.selectionJpgRows.some(
                                          (item) =>
                                            item.isSectionFile || item.isTmbFile
                                        ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.batchLock(true)
                                      },
                                    },
                                  },
                                  [_vm._v("\n              锁定\n            ")]
                                )
                              : _vm._e(),
                            _vm.checkPermission("uploadSubmit:lock")
                              ? _c(
                                  "a-button",
                                  {
                                    class:
                                      _vm.taskDetail.taskStatus == "可开始" ||
                                      (_vm.taskDetail.taskStatus == "驳回" &&
                                        _vm.taskDetail.rejectTaskId)
                                        ? "mr-3"
                                        : "",
                                    attrs: {
                                      disabled:
                                        !_vm.selectedJpgRowKeys.length ||
                                        (_vm.selectionJpgRows.length &&
                                          _vm.selectionJpgRows.every(
                                            (item) => !item.wasLock
                                          )) ||
                                        _vm.selectionJpgRows.some(
                                          (item) =>
                                            item.isSectionFile || item.isTmbFile
                                        ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.batchLock(false)
                                      },
                                    },
                                  },
                                  [_vm._v("\n              解锁\n            ")]
                                )
                              : _vm._e(),
                            _vm.checkPermission("uploadSubmit:replace") &&
                            (_vm.taskDetail.taskStatus == "可开始" ||
                              (_vm.taskDetail.taskStatus == "驳回" &&
                                _vm.taskDetail.rejectTaskId)) &&
                            _vm.taskDetail.departName &&
                            _vm.taskDetail.departName.indexOf("日语部") == -1
                              ? _c(
                                  "a-button",
                                  {
                                    staticClass: "mr-3",
                                    attrs: {
                                      disabled: !_vm.rightDataSource.length,
                                    },
                                    on: { click: _vm.changeAll },
                                  },
                                  [_vm._v("\n              替换\n            ")]
                                )
                              : _vm._e(),
                            _vm.checkPermission("uploadSubmit:delete") &&
                            (_vm.taskDetail.taskStatus == "可开始" ||
                              (_vm.taskDetail.taskStatus == "驳回" &&
                                _vm.taskDetail.rejectTaskId))
                              ? _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.selectedJpgRowKeys.length ||
                                        _vm.selectionJpgRows.some(
                                          (item) => item.wasLock
                                        ),
                                      icon: "delete",
                                      type: "danger",
                                    },
                                    on: { click: _vm.deleteJpgImg },
                                  },
                                  [_vm._v("\n              删除\n            ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.taskDetail.departName &&
                            _vm.taskDetail.departName.indexOf("日语部") > -1 &&
                            _vm.rightDataSource.filter(
                              (item) => !item.isSectionFile && !item.isTmbFile
                            ).length,
                          expression:
                            "\n            taskDetail.departName &&\n              taskDetail.departName.indexOf('日语部') > -1 &&\n              rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length\n          ",
                        },
                      ],
                      staticClass: "upload-title",
                    },
                    [_vm._v("\n          完成稿\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.taskDetail.departName &&
                            _vm.taskDetail.departName.indexOf("日语部") > -1 &&
                            _vm.rightDataSource.filter(
                              (item) => !item.isSectionFile && !item.isTmbFile
                            ).length,
                          expression:
                            "\n            taskDetail.departName &&\n              taskDetail.departName.indexOf('日语部') > -1 &&\n              rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length\n          ",
                        },
                      ],
                      staticClass: "upload-title",
                      staticStyle: { left: "calc(50% - 9px)" },
                    },
                    [_vm._v("\n          切片\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "left-content-list box",
                      style: {
                        width:
                          _vm.taskDetail.departName &&
                          _vm.taskDetail.departName.indexOf("日语部") == -1
                            ? "100%"
                            : "calc(50% - 10px)",
                        marginRight:
                          _vm.taskDetail.departName &&
                          _vm.taskDetail.departName.indexOf("日语部") == -1
                            ? ""
                            : "20px",
                        marginTop:
                          _vm.taskDetail.departName &&
                          _vm.taskDetail.departName.indexOf("日语部") == -1
                            ? "25px"
                            : "65px",
                        height:
                          _vm.taskDetail.departName &&
                          _vm.taskDetail.departName.indexOf("日语部") == -1
                            ? " calc(100vh - 375px)"
                            : " calc(100vh - 415px)",
                      },
                      on: {
                        scroll: _vm.boxScroll,
                        mousedown: _vm.handleMouseDown,
                        drop: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleDropTop.apply(null, arguments)
                        },
                        dragenter: function ($event) {
                          $event.preventDefault()
                        },
                        dragover: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.is_show_mask && _vm.viewType != "list",
                            expression: "is_show_mask && viewType != 'list'",
                          },
                        ],
                        staticClass: "mask",
                        style:
                          "width:" +
                          _vm.mask_width +
                          "left:" +
                          _vm.mask_left +
                          "height:" +
                          _vm.mask_height +
                          "top:" +
                          _vm.mask_top,
                      }),
                      _vm.viewType == "list"
                        ? _c("a-table", {
                            class: _vm.rightDataSource.length
                              ? "have-data"
                              : "",
                            attrs: {
                              size: "middle",
                              rowKey: "id",
                              columns: _vm.columns,
                              dataSource: _vm.rightDataSource.filter(
                                (item) => !item.isSectionFile && !item.isTmbFile
                              ),
                              customRow: _vm.visible
                                ? _vm.customRowLeft
                                : () => {},
                              pagination: false,
                              loading: _vm.jpgLoading,
                              scroll: {
                                x: "max-content",
                                y: "calc(100vh - 505px)",
                              },
                              rowSelection: _vm.rowSelection,
                            },
                            on: { change: _vm.handleLeftTableChange },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "fileName",
                                  fn: function (text, record) {
                                    return _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "white-space": "pre-line",
                                        },
                                      },
                                      [
                                        _vm._v(_vm._s(record.fileName)),
                                        record.wasLock
                                          ? _c("a-icon", {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: { type: "lock" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  },
                                },
                                {
                                  key: "cc",
                                  fn: function (text, record) {
                                    return _c("span", {}, [
                                      record.fileSizeList &&
                                      record.fileSizeList.length
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              record.fileSizeList,
                                              function (f, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(f) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                record.width +
                                                  "×" +
                                                  record.height
                                              )
                                            ),
                                          ]),
                                    ])
                                  },
                                },
                                {
                                  key: "fileLength",
                                  fn: function (text, record) {
                                    return _c("span", {}, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            (
                                              record.fileLength /
                                              1024 /
                                              1024
                                            ).toFixed(2) + "M"
                                          ) +
                                          "\n            "
                                      ),
                                    ])
                                  },
                                },
                                {
                                  key: "lastModified",
                                  fn: function (text, record) {
                                    return _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(record.lastModified)),
                                        ]),
                                        record.showCheck
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-right": "15px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "check-icon" },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "close-circle",
                                                        theme: "filled",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.cancelReplaceTable(
                                                            record
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "check-icon" },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "check-circle",
                                                        theme: "filled",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.keepReplaceTable(
                                                            record
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              408109330
                            ),
                          })
                        : _vm._e(),
                      _vm.viewType == "list"
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "line-height": "50px",
                                "padding-left": "16px",
                              },
                            },
                            [
                              _vm._v(
                                "\n            已选择：" +
                                  _vm._s(
                                    _vm.selectionJpgRows.filter(
                                      (item) =>
                                        !item.isSectionFile && !item.isTmbFile
                                    ).length
                                  ) +
                                  "项，共" +
                                  _vm._s(
                                    _vm.rightDataSource.filter(
                                      (item) =>
                                        !item.isSectionFile && !item.isTmbFile
                                    ).length
                                  ) +
                                  "条。\n          "
                              ),
                            ]
                          )
                        : _vm.rightShow
                        ? _c(
                            "div",
                            { staticClass: "prog-div" },
                            [
                              _c("Progress", {
                                attrs: {
                                  width: 300,
                                  progress: _vm.rightNum,
                                  strokeWidth: 10,
                                  showInfo: true,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.viewType == "widthView"
                        ? _c(
                            "a-spin",
                            { attrs: { spinning: _vm.jpgLoading } },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.rightDataSource.length,
                                      expression: "!rightDataSource.length",
                                    },
                                  ],
                                  staticClass: "prog-div",
                                },
                                [
                                  _c("a-empty", {
                                    staticStyle: { margin: "0 auto" },
                                    attrs: { image: _vm.simpleImage },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "draggable",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.rightDataSource.filter(
                                        (item) =>
                                          !item.isSectionFile && !item.isTmbFile
                                      ).length,
                                      expression:
                                        "rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length",
                                    },
                                  ],
                                  staticClass: "img-list",
                                  attrs: {
                                    dragClass: "drag-img",
                                    ghostClass: "ghost",
                                    draggable: false,
                                    animation: 300,
                                    filter: ".not-allowed",
                                  },
                                  on: {
                                    start: function ($event) {
                                      _vm.drag = true
                                    },
                                    end: _vm.leftDragend,
                                  },
                                  model: {
                                    value: _vm.rightDataSource,
                                    callback: function ($$v) {
                                      _vm.rightDataSource = $$v
                                    },
                                    expression: "rightDataSource",
                                  },
                                },
                                _vm._l(
                                  _vm.rightDataSource.filter(
                                    (item) =>
                                      !item.isSectionFile && !item.isTmbFile
                                  ),
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        class: item.wasLock
                                          ? "img-div"
                                          : "img-div",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectRightImg(
                                              item,
                                              index
                                            )
                                          },
                                          dblclick: function ($event) {
                                            return _vm.imgDblclick(item, index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              background: !item.wasLock
                                                ? ""
                                                : "rgba(0,0,0,0.5)",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              class: !item.wasLock
                                                ? ""
                                                : "was-lock-img",
                                              style:
                                                "width:70px;" +
                                                `${
                                                  item.borderColor
                                                    ? "border:2px solid " +
                                                      item.borderColor
                                                    : ""
                                                }`,
                                              attrs: {
                                                src: item.filePath,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", {
                                          class: item.checked
                                            ? "select-img img-content"
                                            : "no-select-img img-content",
                                          style: {
                                            backgroundColor: item.wasLock
                                              ? ""
                                              : "",
                                          },
                                          attrs: { id: item.id },
                                        }),
                                        !_vm.replaceDom ||
                                        _vm.replaceDom.target.id != item.id ||
                                        !_vm.dragChangeValue ||
                                        item.wasLock
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "img-action",
                                                style: {
                                                  justifyContent: !item.wasLock
                                                    ? ""
                                                    : "",
                                                },
                                                on: {
                                                  mouseover: function ($event) {
                                                    _vm.iconColor = "#030303"
                                                  },
                                                  mouseout: function ($event) {
                                                    _vm.iconColor = "#acacac"
                                                  },
                                                },
                                              },
                                              [
                                                _vm.checkPermission(
                                                  "fatchStraw:view"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          mouseover: function (
                                                            $event
                                                          ) {
                                                            _vm.viewColor =
                                                              _vm.color
                                                          },
                                                          mouseout: function (
                                                            $event
                                                          ) {
                                                            _vm.viewColor = ""
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.checkPermission(
                                                          "fatchStraw:view"
                                                        )
                                                          ? _c(
                                                              "a-tooltip",
                                                              {
                                                                attrs: {
                                                                  transitionName:
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        从当前文件查看\n                      "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.checkPermission(
                                                                  "fatchStraw:view"
                                                                )
                                                                  ? _c(
                                                                      "a-icon",
                                                                      {
                                                                        style: {
                                                                          color:
                                                                            _vm.viewColor
                                                                              ? _vm.viewColor
                                                                              : _vm.iconColor,
                                                                        },
                                                                        attrs: {
                                                                          type: "eye",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.viewClick(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !item.wasLock &&
                                                (_vm.taskDetail.taskStatus ==
                                                  "可开始" ||
                                                  (_vm.taskDetail.taskStatus ==
                                                    "驳回" &&
                                                    _vm.taskDetail
                                                      .rejectTaskId))
                                                  ? _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          mouseover: function (
                                                            $event
                                                          ) {
                                                            _vm.replaceColor =
                                                              _vm.color
                                                          },
                                                          mouseout: function (
                                                            $event
                                                          ) {
                                                            _vm.replaceColor =
                                                              ""
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-upload",
                                                          {
                                                            attrs: {
                                                              name: "file",
                                                              accept:
                                                                ".jpg,.psd,.zip",
                                                              showUploadList: false,
                                                              multiple: true,
                                                              "before-upload": (
                                                                file,
                                                                fileList
                                                              ) =>
                                                                _vm.beforeUploadOnly(
                                                                  file,
                                                                  fileList,
                                                                  item
                                                                ),
                                                              "custom-request":
                                                                _vm.handleRequest,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a-tooltip",
                                                              {
                                                                attrs: {
                                                                  transitionName:
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          替换\n                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.checkPermission(
                                                                  "uploadSubmit:replace"
                                                                )
                                                                  ? _c(
                                                                      "change",
                                                                      {
                                                                        attrs: {
                                                                          theme:
                                                                            "outline",
                                                                          size: "18",
                                                                          fill: _vm.replaceColor
                                                                            ? _vm.replaceColor
                                                                            : _vm.iconColor,
                                                                          strokeWidth: 3,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    on: {
                                                      mouseover: function (
                                                        $event
                                                      ) {
                                                        _vm.lockColor =
                                                          _vm.color
                                                      },
                                                      mouseout: function (
                                                        $event
                                                      ) {
                                                        _vm.lockColor = ""
                                                      },
                                                    },
                                                  },
                                                  [
                                                    !item.wasLock &&
                                                    _vm.checkPermission(
                                                      "uploadSubmit:lock"
                                                    )
                                                      ? _c(
                                                          "a-tooltip",
                                                          {
                                                            attrs: {
                                                              transitionName:
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  "\n                        锁定\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("a-icon", {
                                                              style: {
                                                                color:
                                                                  _vm.lockColor
                                                                    ? _vm.lockColor
                                                                    : _vm.iconColor,
                                                              },
                                                              attrs: {
                                                                type: "lock",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.lock(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        )
                                                      : _vm.checkPermission(
                                                          "uploadSubmit:lock"
                                                        ) && item.wasLock
                                                      ? _c(
                                                          "a-tooltip",
                                                          {
                                                            attrs: {
                                                              transitionName:
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  "\n                        解锁\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("a-icon", {
                                                              style: {
                                                                color:
                                                                  item.wasLock
                                                                    ? _vm.color
                                                                    : _vm.lockColor
                                                                    ? _vm.lockColor
                                                                    : _vm.iconColor,
                                                              },
                                                              attrs: {
                                                                type: "unlock",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.unlock(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "img-action",
                                                staticStyle: {
                                                  opacity: "1",
                                                  "z-index": "1",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "check-icon" },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "close-circle",
                                                        theme: "filled",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.cancelReplace.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "check-icon" },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "check-circle",
                                                        theme: "filled",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.keepReplace.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                        item.modifiedCount
                                          ? _c(
                                              "div",
                                              { staticClass: "img-count" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.modifiedCount) +
                                                    "改稿"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", { staticClass: "img-name" }, [
                                          _vm._v(_vm._s(item.fileName)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm.viewType == "heightView"
                        ? _c(
                            "a-spin",
                            { attrs: { spinning: _vm.jpgLoading } },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.rightShow &&
                                        !_vm.rightDataSource.length,
                                      expression:
                                        "rightShow && !rightDataSource.length",
                                    },
                                  ],
                                  staticClass: "prog-div",
                                },
                                [
                                  _c("a-empty", {
                                    staticStyle: { margin: "0 auto" },
                                    attrs: { image: _vm.simpleImage },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "draggable",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.rightDataSource.filter(
                                        (item) =>
                                          !item.isSectionFile && !item.isTmbFile
                                      ).length,
                                      expression:
                                        "rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length",
                                    },
                                  ],
                                  staticClass: "img-list",
                                  attrs: {
                                    draggable: false,
                                    dragClass: "drag-img",
                                    ghostClass: "ghost",
                                    animation: 300,
                                    filter: ".not-allowed",
                                  },
                                  on: {
                                    start: function ($event) {
                                      _vm.drag = true
                                    },
                                    end: _vm.leftDragend,
                                  },
                                  model: {
                                    value: _vm.rightDataSource,
                                    callback: function ($$v) {
                                      _vm.rightDataSource = $$v
                                    },
                                    expression: "rightDataSource",
                                  },
                                },
                                _vm._l(
                                  _vm.rightDataSource.filter(
                                    (item) =>
                                      !item.isSectionFile && !item.isTmbFile
                                  ),
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        class: item.wasLock
                                          ? "img-div-height"
                                          : "img-div-height",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectRightImg(
                                              item,
                                              index
                                            )
                                          },
                                          dblclick: function ($event) {
                                            return _vm.imgDblclick(item, index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              background: !item.wasLock
                                                ? ""
                                                : "rgba(0,0,0,0.5)",
                                              maxWidth: "100%",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              class: !item.wasLock
                                                ? ""
                                                : "was-lock-img",
                                              style:
                                                "height:350px;" +
                                                `${
                                                  item.borderColor
                                                    ? "border:2px solid " +
                                                      item.borderColor
                                                    : ""
                                                }`,
                                              attrs: {
                                                src: item.filePath,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", {
                                          class: item.checked
                                            ? "select-img img-content"
                                            : "no-select-img img-content",
                                          style: {
                                            backgroundColor: item.wasLock
                                              ? ""
                                              : "",
                                          },
                                          attrs: { id: item.id },
                                        }),
                                        !_vm.replaceDom ||
                                        _vm.replaceDom.target.id != item.id ||
                                        !_vm.dragChangeValue ||
                                        item.wasLock
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "img-action",
                                                style: {
                                                  justifyContent: !item.wasLock
                                                    ? ""
                                                    : "",
                                                },
                                                on: {
                                                  mouseover: function ($event) {
                                                    _vm.iconColor = "#030303"
                                                  },
                                                  mouseout: function ($event) {
                                                    _vm.iconColor = "#acacac"
                                                  },
                                                },
                                              },
                                              [
                                                _vm.checkPermission(
                                                  "fatchStraw:view"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          mouseover: function (
                                                            $event
                                                          ) {
                                                            _vm.viewColor =
                                                              _vm.color
                                                          },
                                                          mouseout: function (
                                                            $event
                                                          ) {
                                                            _vm.viewColor = ""
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.checkPermission(
                                                          "fatchStraw:view"
                                                        )
                                                          ? _c(
                                                              "a-tooltip",
                                                              {
                                                                attrs: {
                                                                  transitionName:
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        从当前文件查看\n                      "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.checkPermission(
                                                                  "fatchStraw:view"
                                                                )
                                                                  ? _c(
                                                                      "a-icon",
                                                                      {
                                                                        style: {
                                                                          color:
                                                                            _vm.viewColor
                                                                              ? _vm.viewColor
                                                                              : _vm.iconColor,
                                                                        },
                                                                        attrs: {
                                                                          type: "eye",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.viewClick(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !item.wasLock &&
                                                (_vm.taskDetail.taskStatus ==
                                                  "可开始" ||
                                                  (_vm.taskDetail.taskStatus ==
                                                    "驳回" &&
                                                    _vm.taskDetail
                                                      .rejectTaskId))
                                                  ? _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          mouseover: function (
                                                            $event
                                                          ) {
                                                            _vm.replaceColor =
                                                              _vm.color
                                                          },
                                                          mouseout: function (
                                                            $event
                                                          ) {
                                                            _vm.replaceColor =
                                                              ""
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-upload",
                                                          {
                                                            attrs: {
                                                              name: "file",
                                                              accept:
                                                                ".jpg,.psd,.zip",
                                                              showUploadList: false,
                                                              multiple: true,
                                                              "before-upload": (
                                                                file,
                                                                fileList
                                                              ) =>
                                                                _vm.beforeUploadOnly(
                                                                  file,
                                                                  fileList,
                                                                  item
                                                                ),
                                                              "custom-request":
                                                                _vm.handleRequest,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a-tooltip",
                                                              {
                                                                attrs: {
                                                                  transitionName:
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          替换\n                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.checkPermission(
                                                                  "uploadSubmit:replace"
                                                                )
                                                                  ? _c(
                                                                      "change",
                                                                      {
                                                                        attrs: {
                                                                          theme:
                                                                            "outline",
                                                                          size: "18",
                                                                          fill: _vm.replaceColor
                                                                            ? _vm.replaceColor
                                                                            : _vm.iconColor,
                                                                          strokeWidth: 3,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    on: {
                                                      mouseover: function (
                                                        $event
                                                      ) {
                                                        _vm.lockColor =
                                                          _vm.color
                                                      },
                                                      mouseout: function (
                                                        $event
                                                      ) {
                                                        _vm.lockColor = ""
                                                      },
                                                    },
                                                  },
                                                  [
                                                    !item.wasLock &&
                                                    _vm.checkPermission(
                                                      "uploadSubmit:lock"
                                                    )
                                                      ? _c(
                                                          "a-tooltip",
                                                          {
                                                            attrs: {
                                                              transitionName:
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  "\n                        锁定\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("a-icon", {
                                                              style: {
                                                                color:
                                                                  _vm.lockColor
                                                                    ? _vm.lockColor
                                                                    : _vm.iconColor,
                                                              },
                                                              attrs: {
                                                                type: "lock",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.lock(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        )
                                                      : _vm.checkPermission(
                                                          "uploadSubmit:lock"
                                                        ) && item.wasLock
                                                      ? _c(
                                                          "a-tooltip",
                                                          {
                                                            attrs: {
                                                              transitionName:
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  "\n                        解锁\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("a-icon", {
                                                              style: {
                                                                color:
                                                                  item.wasLock
                                                                    ? _vm.color
                                                                    : _vm.lockColor
                                                                    ? _vm.lockColor
                                                                    : _vm.iconColor,
                                                              },
                                                              attrs: {
                                                                type: "unlock",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.unlock(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "img-action",
                                                staticStyle: {
                                                  opacity: "1",
                                                  "z-index": "1",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "check-icon" },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "close-circle",
                                                        theme: "filled",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.cancelReplace.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "check-icon" },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "check-circle",
                                                        theme: "filled",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.keepReplace.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                        item.modifiedCount
                                          ? _c(
                                              "div",
                                              { staticClass: "img-count" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.modifiedCount) +
                                                    "改稿"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", { staticClass: "img-name" }, [
                                          _vm._v(_vm._s(item.fileName)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.taskDetail.departName &&
                  _vm.taskDetail.departName.indexOf("日语部") > -1
                    ? _c(
                        "div",
                        {
                          staticClass: "left-content-list box",
                          style: {
                            width:
                              _vm.taskDetail.departName &&
                              _vm.taskDetail.departName.indexOf("日语部") == -1
                                ? "100%"
                                : "calc(50% - 10px)",
                            marginTop:
                              _vm.taskDetail.departName &&
                              _vm.taskDetail.departName.indexOf("日语部") == -1
                                ? "25px"
                                : "65px",
                            height:
                              _vm.taskDetail.departName &&
                              _vm.taskDetail.departName.indexOf("日语部") == -1
                                ? " calc(100vh - 375px)"
                                : " calc(100vh - 415px)",
                          },
                          on: {
                            scroll: _vm.boxScroll,
                            mousedown: _vm.handleMouseDownTwo,
                            drop: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleDropTop.apply(null, arguments)
                            },
                            dragenter: function ($event) {
                              $event.preventDefault()
                            },
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.is_show_mask_two &&
                                  _vm.viewType != "list",
                                expression:
                                  "is_show_mask_two && viewType != 'list'",
                              },
                            ],
                            staticClass: "mask_two",
                            style:
                              "width:" +
                              _vm.mask_width +
                              "left:" +
                              _vm.mask_left +
                              "height:" +
                              _vm.mask_height +
                              "top:" +
                              _vm.mask_top,
                          }),
                          _vm.viewType == "list"
                            ? _c("a-table", {
                                class: _vm.rightDataSource.length
                                  ? "have-data"
                                  : "",
                                attrs: {
                                  size: "middle",
                                  rowKey: "id",
                                  columns: _vm.columns,
                                  dataSource: _vm.rightDataSource.filter(
                                    (item) =>
                                      item.isSectionFile || item.isTmbFile
                                  ),
                                  customRow: _vm.visible
                                    ? _vm.customRowLeft
                                    : () => {},
                                  pagination: false,
                                  loading: _vm.jpgLoading,
                                  scroll: {
                                    x: "max-content",
                                    y: "calc(100vh - 505px)",
                                  },
                                  rowSelection: _vm.rowSelection,
                                },
                                on: { change: _vm.handleLeftTableChange },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "fileName",
                                      fn: function (text, record) {
                                        return _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "white-space": "pre-line",
                                            },
                                          },
                                          [
                                            _vm._v(_vm._s(record.fileName)),
                                            record.wasLock
                                              ? _c("a-icon", {
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                  },
                                                  attrs: { type: "lock" },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                    {
                                      key: "cc",
                                      fn: function (text, record) {
                                        return _c("span", {}, [
                                          record.fileSizeList &&
                                          record.fileSizeList.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  record.fileSizeList,
                                                  function (f, index) {
                                                    return _c(
                                                      "div",
                                                      { key: index },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(f) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    record.width +
                                                      "×" +
                                                      record.height
                                                  )
                                                ),
                                              ]),
                                        ])
                                      },
                                    },
                                    {
                                      key: "fileLength",
                                      fn: function (text, record) {
                                        return _c("span", {}, [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                (
                                                  record.fileLength /
                                                  1024 /
                                                  1024
                                                ).toFixed(2) + "M"
                                              ) +
                                              "\n            "
                                          ),
                                        ])
                                      },
                                    },
                                    {
                                      key: "lastModified",
                                      fn: function (text, record) {
                                        return _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "justify-content":
                                                "space-between",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(record.lastModified)
                                              ),
                                            ]),
                                            record.showCheck
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "15px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "check-icon",
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "close-circle",
                                                            theme: "filled",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.cancelReplaceTable(
                                                                record
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "check-icon",
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "check-circle",
                                                            theme: "filled",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.keepReplaceTable(
                                                                record
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  408109330
                                ),
                              })
                            : _vm._e(),
                          _vm.viewType == "list" &&
                          _vm.taskDetail.departName &&
                          _vm.taskDetail.departName.indexOf("日语部") > -1
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "line-height": "50px",
                                    "padding-left": "16px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            已选择：" +
                                      _vm._s(
                                        _vm.selectionJpgRows.filter(
                                          (item) =>
                                            item.isSectionFile || item.isTmbFile
                                        ).length
                                      ) +
                                      "项，共" +
                                      _vm._s(
                                        _vm.rightDataSource.filter(
                                          (item) =>
                                            item.isSectionFile || item.isTmbFile
                                        ).length
                                      ) +
                                      "条。\n          "
                                  ),
                                ]
                              )
                            : _vm.rightShow
                            ? _c(
                                "div",
                                { staticClass: "prog-div" },
                                [
                                  _c("Progress", {
                                    attrs: {
                                      width: 300,
                                      progress: _vm.rightNum,
                                      strokeWidth: 10,
                                      showInfo: true,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.viewType == "widthView"
                            ? _c(
                                "a-spin",
                                { attrs: { spinning: _vm.jpgLoading } },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.rightDataSource.length,
                                          expression: "!rightDataSource.length",
                                        },
                                      ],
                                      staticClass: "prog-div",
                                    },
                                    [
                                      _c("a-empty", {
                                        staticStyle: { margin: "0 auto" },
                                        attrs: { image: _vm.simpleImage },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "draggable",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.taskDetail.departName &&
                                            _vm.taskDetail.departName.indexOf(
                                              "日语部"
                                            ) > -1 &&
                                            _vm.rightDataSource.filter(
                                              (item) =>
                                                item.isSectionFile ||
                                                item.isTmbFile
                                            ).length,
                                          expression:
                                            "\n                taskDetail.departName &&\n                  taskDetail.departName.indexOf('日语部') > -1 &&\n                  rightDataSource.filter(item => item.isSectionFile || item.isTmbFile).length\n              ",
                                        },
                                      ],
                                      staticClass: "img-list",
                                      attrs: {
                                        dragClass: "drag-img",
                                        ghostClass: "ghost",
                                        draggable: false,
                                        animation: 300,
                                        filter: ".not-allowed",
                                      },
                                      on: {
                                        start: function ($event) {
                                          _vm.drag = true
                                        },
                                        end: _vm.leftDragend,
                                      },
                                      model: {
                                        value: _vm.rightDataSource,
                                        callback: function ($$v) {
                                          _vm.rightDataSource = $$v
                                        },
                                        expression: "rightDataSource",
                                      },
                                    },
                                    _vm._l(
                                      _vm.rightDataSource.filter(
                                        (item) =>
                                          item.isSectionFile || item.isTmbFile
                                      ),
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            class: item.wasLock
                                              ? "img-div"
                                              : "img-div",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectRightImg(
                                                  item,
                                                  index
                                                )
                                              },
                                              dblclick: function ($event) {
                                                return _vm.imgDblclick(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                style: {
                                                  background: !item.wasLock
                                                    ? ""
                                                    : "rgba(0,0,0,0.5)",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  class: !item.wasLock
                                                    ? ""
                                                    : "was-lock-img",
                                                  style:
                                                    "width:70px;" +
                                                    `${
                                                      item.borderColor
                                                        ? "border:2px solid " +
                                                          item.borderColor
                                                        : ""
                                                    }`,
                                                  attrs: {
                                                    src: item.filePath,
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", {
                                              class: item.checked
                                                ? "select-img img-content"
                                                : "no-select-img img-content",
                                              style: {
                                                backgroundColor: item.wasLock
                                                  ? ""
                                                  : "",
                                              },
                                              attrs: { id: item.id },
                                            }),
                                            !_vm.replaceDom ||
                                            _vm.replaceDom.target.id !=
                                              item.id ||
                                            !_vm.dragChangeValue ||
                                            item.wasLock
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "img-action",
                                                    style: {
                                                      justifyContent:
                                                        !item.wasLock ? "" : "",
                                                    },
                                                    on: {
                                                      mouseover: function (
                                                        $event
                                                      ) {
                                                        _vm.iconColor =
                                                          "#030303"
                                                      },
                                                      mouseout: function (
                                                        $event
                                                      ) {
                                                        _vm.iconColor =
                                                          "#acacac"
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.checkPermission(
                                                      "fatchStraw:view"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            on: {
                                                              mouseover:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.viewColor =
                                                                    _vm.color
                                                                },
                                                              mouseout:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.viewColor =
                                                                    ""
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _vm.checkPermission(
                                                              "fatchStraw:view"
                                                            )
                                                              ? _c(
                                                                  "a-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      transitionName:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        从当前文件查看\n                      "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.checkPermission(
                                                                      "fatchStraw:view"
                                                                    )
                                                                      ? _c(
                                                                          "a-icon",
                                                                          {
                                                                            style:
                                                                              {
                                                                                color:
                                                                                  _vm.viewColor
                                                                                    ? _vm.viewColor
                                                                                    : _vm.iconColor,
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: "eye",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.viewClick(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !item.wasLock &&
                                                    (_vm.taskDetail
                                                      .taskStatus == "可开始" ||
                                                      (_vm.taskDetail
                                                        .taskStatus == "驳回" &&
                                                        _vm.taskDetail
                                                          .rejectTaskId))
                                                      ? _c(
                                                          "div",
                                                          {
                                                            on: {
                                                              mouseover:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.replaceColor =
                                                                    _vm.color
                                                                },
                                                              mouseout:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.replaceColor =
                                                                    ""
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a-upload",
                                                              {
                                                                attrs: {
                                                                  name: "file",
                                                                  accept:
                                                                    ".jpg,.psd,.zip",
                                                                  showUploadList: false,
                                                                  multiple: true,
                                                                  "before-upload":
                                                                    (
                                                                      file,
                                                                      fileList
                                                                    ) =>
                                                                      _vm.beforeUploadOnly(
                                                                        file,
                                                                        fileList,
                                                                        item
                                                                      ),
                                                                  "custom-request":
                                                                    _vm.handleRequest,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "a-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      transitionName:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          替换\n                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.checkPermission(
                                                                      "uploadSubmit:replace"
                                                                    )
                                                                      ? _c(
                                                                          "change",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                theme:
                                                                                  "outline",
                                                                                size: "18",
                                                                                fill: _vm.replaceColor
                                                                                  ? _vm.replaceColor
                                                                                  : _vm.iconColor,
                                                                                strokeWidth: 3,
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          mouseover: function (
                                                            $event
                                                          ) {
                                                            _vm.lockColor =
                                                              _vm.color
                                                          },
                                                          mouseout: function (
                                                            $event
                                                          ) {
                                                            _vm.lockColor = ""
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.checkPermission(
                                                          "uploadSubmit:lock"
                                                        ) && item.wasLock
                                                          ? _c(
                                                              "a-tooltip",
                                                              {
                                                                attrs: {
                                                                  transitionName:
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        解锁\n                      "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("a-icon", {
                                                                  style: {
                                                                    color:
                                                                      item.wasLock
                                                                        ? _vm.color
                                                                        : _vm.lockColor
                                                                        ? _vm.lockColor
                                                                        : _vm.iconColor,
                                                                  },
                                                                  attrs: {
                                                                    type: "unlock",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.unlock(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "img-action",
                                                    staticStyle: {
                                                      opacity: "1",
                                                      "z-index": "1",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "check-icon",
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "close-circle",
                                                            theme: "filled",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.cancelReplace.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "check-icon",
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "check-circle",
                                                            theme: "filled",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.keepReplace.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                            item.modifiedCount
                                              ? _c(
                                                  "div",
                                                  { staticClass: "img-count" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.modifiedCount
                                                      ) + "改稿"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "img-name" },
                                              [_vm._v(_vm._s(item.fileName))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : _vm.viewType == "heightView"
                            ? _c(
                                "a-spin",
                                { attrs: { spinning: _vm.jpgLoading } },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.rightShow &&
                                            !_vm.rightDataSource.length,
                                          expression:
                                            "rightShow && !rightDataSource.length",
                                        },
                                      ],
                                      staticClass: "prog-div",
                                    },
                                    [
                                      _c("a-empty", {
                                        staticStyle: { margin: "0 auto" },
                                        attrs: { image: _vm.simpleImage },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "draggable",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.taskDetail.departName &&
                                            _vm.taskDetail.departName.indexOf(
                                              "日语部"
                                            ) > -1 &&
                                            _vm.rightDataSource.filter(
                                              (item) =>
                                                item.isSectionFile ||
                                                item.isTmbFile
                                            ).length,
                                          expression:
                                            "\n                taskDetail.departName &&\n                  taskDetail.departName.indexOf('日语部') > -1 &&\n                  rightDataSource.filter(item => item.isSectionFile || item.isTmbFile).length\n              ",
                                        },
                                      ],
                                      staticClass: "img-list",
                                      attrs: {
                                        draggable: false,
                                        dragClass: "drag-img",
                                        ghostClass: "ghost",
                                        animation: 300,
                                        filter: ".not-allowed",
                                      },
                                      on: {
                                        start: function ($event) {
                                          _vm.drag = true
                                        },
                                        end: _vm.leftDragend,
                                      },
                                      model: {
                                        value: _vm.rightDataSource,
                                        callback: function ($$v) {
                                          _vm.rightDataSource = $$v
                                        },
                                        expression: "rightDataSource",
                                      },
                                    },
                                    _vm._l(
                                      _vm.rightDataSource.filter(
                                        (item) =>
                                          item.isSectionFile || item.isTmbFile
                                      ),
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            class: item.wasLock
                                              ? "img-div-height"
                                              : "img-div-height",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectRightImg(
                                                  item,
                                                  index
                                                )
                                              },
                                              dblclick: function ($event) {
                                                return _vm.imgDblclick(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                style: {
                                                  background: !item.wasLock
                                                    ? ""
                                                    : "rgba(0,0,0,0.5)",
                                                  maxWidth: "100%",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  class: !item.wasLock
                                                    ? ""
                                                    : "was-lock-img",
                                                  style:
                                                    "height:350px;max-width:100%;" +
                                                    `${
                                                      item.borderColor
                                                        ? "border:2px solid " +
                                                          item.borderColor
                                                        : ""
                                                    }`,
                                                  attrs: {
                                                    src: item.filePath,
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", {
                                              class: item.checked
                                                ? "select-img img-content"
                                                : "no-select-img img-content",
                                              style: {
                                                backgroundColor: item.wasLock
                                                  ? ""
                                                  : "",
                                              },
                                              attrs: { id: item.id },
                                            }),
                                            !_vm.replaceDom ||
                                            _vm.replaceDom.target.id !=
                                              item.id ||
                                            !_vm.dragChangeValue ||
                                            item.wasLock
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "img-action",
                                                    style: {
                                                      justifyContent:
                                                        !item.wasLock ? "" : "",
                                                    },
                                                    on: {
                                                      mouseover: function (
                                                        $event
                                                      ) {
                                                        _vm.iconColor =
                                                          "#030303"
                                                      },
                                                      mouseout: function (
                                                        $event
                                                      ) {
                                                        _vm.iconColor =
                                                          "#acacac"
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.checkPermission(
                                                      "fatchStraw:view"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            on: {
                                                              mouseover:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.viewColor =
                                                                    _vm.color
                                                                },
                                                              mouseout:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.viewColor =
                                                                    ""
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _vm.checkPermission(
                                                              "fatchStraw:view"
                                                            )
                                                              ? _c(
                                                                  "a-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      transitionName:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        从当前文件查看\n                      "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.checkPermission(
                                                                      "fatchStraw:view"
                                                                    )
                                                                      ? _c(
                                                                          "a-icon",
                                                                          {
                                                                            style:
                                                                              {
                                                                                color:
                                                                                  _vm.viewColor
                                                                                    ? _vm.viewColor
                                                                                    : _vm.iconColor,
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: "eye",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.viewClick(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !item.wasLock &&
                                                    (_vm.taskDetail
                                                      .taskStatus == "可开始" ||
                                                      (_vm.taskDetail
                                                        .taskStatus == "驳回" &&
                                                        _vm.taskDetail
                                                          .rejectTaskId))
                                                      ? _c(
                                                          "div",
                                                          {
                                                            on: {
                                                              mouseover:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.replaceColor =
                                                                    _vm.color
                                                                },
                                                              mouseout:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.replaceColor =
                                                                    ""
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a-upload",
                                                              {
                                                                attrs: {
                                                                  name: "file",
                                                                  accept:
                                                                    ".jpg,.psd,.zip",
                                                                  showUploadList: false,
                                                                  multiple: true,
                                                                  "before-upload":
                                                                    (
                                                                      file,
                                                                      fileList
                                                                    ) =>
                                                                      _vm.beforeUploadOnly(
                                                                        file,
                                                                        fileList,
                                                                        item
                                                                      ),
                                                                  "custom-request":
                                                                    _vm.handleRequest,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "a-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      transitionName:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          替换\n                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.checkPermission(
                                                                      "uploadSubmit:replace"
                                                                    )
                                                                      ? _c(
                                                                          "change",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                theme:
                                                                                  "outline",
                                                                                size: "18",
                                                                                fill: _vm.replaceColor
                                                                                  ? _vm.replaceColor
                                                                                  : _vm.iconColor,
                                                                                strokeWidth: 3,
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          mouseover: function (
                                                            $event
                                                          ) {
                                                            _vm.lockColor =
                                                              _vm.color
                                                          },
                                                          mouseout: function (
                                                            $event
                                                          ) {
                                                            _vm.lockColor = ""
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.checkPermission(
                                                          "uploadSubmit:lock"
                                                        ) && item.wasLock
                                                          ? _c(
                                                              "a-tooltip",
                                                              {
                                                                attrs: {
                                                                  transitionName:
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        解锁\n                      "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("a-icon", {
                                                                  style: {
                                                                    color:
                                                                      item.wasLock
                                                                        ? _vm.color
                                                                        : _vm.lockColor
                                                                        ? _vm.lockColor
                                                                        : _vm.iconColor,
                                                                  },
                                                                  attrs: {
                                                                    type: "unlock",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.unlock(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "img-action",
                                                    staticStyle: {
                                                      opacity: "1",
                                                      "z-index": "1",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "check-icon",
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "close-circle",
                                                            theme: "filled",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.cancelReplace.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "check-icon",
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "check-circle",
                                                            theme: "filled",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.keepReplace.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                            item.modifiedCount
                                              ? _c(
                                                  "div",
                                                  { staticClass: "img-count" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.modifiedCount
                                                      ) + "改稿"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "img-name" },
                                              [_vm._v(_vm._s(item.fileName))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm.visible
                ? _c(
                    "a-col",
                    {
                      staticClass: "right-content",
                      attrs: { span: _vm.visible ? 9 : 0 },
                    },
                    [
                      _c("div", { staticClass: "left-content-button-list" }, [
                        _c("div"),
                        _vm.taskDetail.taskStatus == "可开始" ||
                        (_vm.taskDetail.taskStatus == "驳回" &&
                          _vm.taskDetail.rejectTaskId)
                          ? _c(
                              "div",
                              [
                                _vm.checkPermission("uploadSubmit:lock")
                                  ? _c(
                                      "a-button",
                                      {
                                        staticClass: "mr-3",
                                        attrs: {
                                          disabled:
                                            !_vm.selectedJpgRowKeys.length ||
                                            (_vm.selectionJpgRows.length &&
                                              _vm.selectionJpgRows.every(
                                                (item) => item.wasLock
                                              )) ||
                                            _vm.selectionJpgRows.some(
                                              (item) =>
                                                item.isSectionFile ||
                                                item.isTmbFile
                                            ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.batchLock(true)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              锁定\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.checkPermission("uploadSubmit:lock")
                                  ? _c(
                                      "a-button",
                                      {
                                        staticClass: "mr-3",
                                        attrs: {
                                          disabled:
                                            !_vm.selectedJpgRowKeys.length ||
                                            (_vm.selectionJpgRows.length &&
                                              _vm.selectionJpgRows.every(
                                                (item) => !item.wasLock
                                              )) ||
                                            _vm.selectionJpgRows.some(
                                              (item) =>
                                                item.isSectionFile ||
                                                item.isTmbFile
                                            ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.batchLock(false)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              解锁\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.checkPermission("uploadSubmit:replace") &&
                                _vm.taskDetail.departName &&
                                _vm.taskDetail.departName.indexOf("日语部") ==
                                  -1
                                  ? _c(
                                      "a-button",
                                      {
                                        staticClass: "mr-3",
                                        attrs: {
                                          disabled: !_vm.rightDataSource.length,
                                        },
                                        on: { click: _vm.changeAll },
                                      },
                                      [
                                        _vm._v(
                                          "\n              替换\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.checkPermission("uploadSubmit:delete")
                                  ? _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          disabled:
                                            !_vm.selectedJpgRowKeys.length ||
                                            _vm.selectionJpgRows.some(
                                              (item) => item.wasLock
                                            ),
                                          icon: "delete",
                                          type: "danger",
                                        },
                                        on: { click: _vm.deleteJpgImg },
                                      },
                                      [
                                        _vm._v(
                                          "\n              删除\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "left-content-list" }, [
                        _c(
                          "div",
                          { staticStyle: { height: "100%", width: "100%" } },
                          [
                            _vm.taskDetail.departName &&
                            _vm.taskDetail.departName.indexOf("日语部") == -1
                              ? _c(
                                  "a-upload-dragger",
                                  {
                                    attrs: {
                                      name: "file",
                                      accept: ".zip,." + _vm.defaultType,
                                      showUploadList: false,
                                      multiple: true,
                                      "before-upload": _vm.beforeUpload,
                                      "custom-request": _vm.handleRequest,
                                    },
                                  },
                                  [
                                    _c(
                                      "a-spin",
                                      {
                                        staticStyle: { height: "100%" },
                                        attrs: { spinning: _vm.loading },
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: { "font-size": "24px" },
                                          attrs: {
                                            slot: "indicator",
                                            type: "loading",
                                            spin: "",
                                          },
                                          slot: "indicator",
                                        }),
                                        !_vm.changeFileList.length
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "100%",
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: { flex: "1" },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "ant-upload-drag-icon",
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "inbox",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "ant-upload-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      点击或将文件拖拽到这里拼接或替换\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "upload-list" },
                                              [
                                                _vm.viewType == "list"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          height: "100%",
                                                        },
                                                      },
                                                      [
                                                        _c("a-table", {
                                                          class: _vm
                                                            .changeFileList
                                                            .length
                                                            ? "have-data"
                                                            : "",
                                                          attrs: {
                                                            size: "middle",
                                                            rowKey: "id",
                                                            columns:
                                                              _vm.columnsRight,
                                                            dataSource:
                                                              _vm.changeFileList,
                                                            customRow:
                                                              _vm.customRow,
                                                            rowSelection: {
                                                              selectedRowKeys:
                                                                _vm.selectedRightRowKeys,
                                                              onChange:
                                                                _vm.onRightSelectChange,
                                                              columnWidth: 45,
                                                            },
                                                            pagination: false,
                                                            scroll: {
                                                              x: "max-content",
                                                              y: "calc(100vh - 460px)",
                                                            },
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.handleRihgtTableChange,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "cc",
                                                                fn: function (
                                                                  text,
                                                                  record
                                                                ) {
                                                                  return _c(
                                                                    "span",
                                                                    {},
                                                                    [
                                                                      record.fileSizeList &&
                                                                      record
                                                                        .fileSizeList
                                                                        .length
                                                                        ? _c(
                                                                            "div",
                                                                            _vm._l(
                                                                              record.fileSizeList,
                                                                              function (
                                                                                f,
                                                                                index
                                                                              ) {
                                                                                return _c(
                                                                                  "div",
                                                                                  {
                                                                                    key: index,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                            " +
                                                                                        _vm._s(
                                                                                          f
                                                                                        ) +
                                                                                        "\n                          "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  record.width +
                                                                                    "×" +
                                                                                    record.height
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  )
                                                                },
                                                              },
                                                              {
                                                                key: "fileName",
                                                                fn: function (
                                                                  text,
                                                                  record
                                                                ) {
                                                                  return _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "white-space":
                                                                            "pre-line",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          record.fileName
                                                                        ) +
                                                                          "\n                      "
                                                                      ),
                                                                    ]
                                                                  )
                                                                },
                                                              },
                                                              {
                                                                key: "fileLength",
                                                                fn: function (
                                                                  text,
                                                                  record
                                                                ) {
                                                                  return _c(
                                                                    "span",
                                                                    {},
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            (
                                                                              record.fileLength /
                                                                              1024 /
                                                                              1024
                                                                            ).toFixed(
                                                                              2
                                                                            ) +
                                                                              "M"
                                                                          ) +
                                                                          "\n                      "
                                                                      ),
                                                                    ]
                                                                  )
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2289435583
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm.viewType == "widthView"
                                                  ? _c(
                                                      "div",
                                                      _vm._l(
                                                        _vm.changeFileList,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "img-div",
                                                              attrs: {
                                                                draggable:
                                                                  item.checked,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.selectChangeImg(
                                                                      index
                                                                    )
                                                                  },
                                                                dragend:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return ((
                                                                      e
                                                                    ) =>
                                                                      _vm.dragend(
                                                                        e,
                                                                        item
                                                                      )).apply(
                                                                      null,
                                                                      arguments
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                style:
                                                                  "width:70px;",
                                                                attrs: {
                                                                  src: item.filePath,
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _c("div", {
                                                                class:
                                                                  item.checked
                                                                    ? "select-img"
                                                                    : "no-select-img",
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "img-name",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item &&
                                                                        item.fileName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm.viewType == "heightView"
                                                  ? _c(
                                                      "div",
                                                      _vm._l(
                                                        _vm.changeFileList,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "img-div-height",
                                                              attrs: {
                                                                draggable:
                                                                  item.checked,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.selectChangeImg(
                                                                      index
                                                                    )
                                                                  },
                                                                dragend:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return ((
                                                                      e
                                                                    ) =>
                                                                      _vm.dragend(
                                                                        e,
                                                                        item
                                                                      )).apply(
                                                                      null,
                                                                      arguments
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  "max-height":
                                                                    "350px",
                                                                },
                                                                attrs: {
                                                                  src: item.filePath,
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _c("div", {
                                                                class:
                                                                  item.checked
                                                                    ? "select-img"
                                                                    : "no-select-img",
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "img-name",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.fileName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("playvideo-modal", {
        ref: "videoModal",
        attrs: { width: _vm.imagePlayWidth },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }