var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      staticClass: "video-modal",
      attrs: {
        width: _vm.windowWidth,
        visible: _vm.visible,
        footer: null,
        zIndex: 9999,
        maskClosable: false,
        closable: false,
        dialogStyle: { top: "20px" },
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c("div", { staticClass: "video-content", style: _vm.backCss }, [
        _c(
          "div",
          {
            staticClass: "img-content-modal",
            style: {
              maxWidth: _vm.windowWidth + "px",
              maxHeight: _vm.windowHeight - 90 + "px",
              overflowY: "auto",
              overflowX: "hidden",
            },
            on: { scroll: _vm.changeScroll },
          },
          _vm._l(_vm.urls, function (img, index) {
            return _c(
              "div",
              {
                key: index,
                style: { display: "flex", justifyContent: "center" },
              },
              [
                _c("img", {
                  staticClass: "video-image",
                  style: {
                    userSelect: "none",
                    transform: `rotate(${_vm.angle}deg)`,
                    maxWidth: _vm.width,
                    height: "auto",
                  },
                  attrs: { src: img.url, alt: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                }),
              ]
            )
          }),
          0
        ),
        _vm.actionShow
          ? _c(
              "div",
              {
                staticClass: "image-action",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("a-icon", {
                      attrs: { type: "zoom-in" },
                      on: {
                        click: function ($event) {
                          return _vm.scale("amp")
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-icon", {
                      attrs: { type: "zoom-out" },
                      on: {
                        click: function ($event) {
                          return _vm.scale("reduce")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.actionShow
          ? _c(
              "div",
              { staticClass: "image-close", on: { click: _vm.handleCancel } },
              [_c("a-icon", { attrs: { type: "close" } })],
              1
            )
          : _vm._e(),
        _vm.actionShow
          ? _c(
              "div",
              { staticClass: "image-selection" },
              _vm._l(_vm.urls, function (img, index) {
                return _c(
                  "div",
                  {
                    key: index + "name",
                    class: img.selected ? "image-selected" : "",
                    on: {
                      click: function ($event) {
                        return _vm.selectImg(index)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(img.name) + "\n      ")]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }