<template>
  <a-spin :spinning="spinning">
    <div class="my-task" :style="backCss">
      <div class="left" v-if="taskList && taskList.length" style="display: flex;flex-direction: column;">
        <a-icon
          @click="prev"
          :style="isDesktop() ? 'font-size:30px' : 'font-size:15px'"
          v-if="taskList.findIndex(item => item.taskId == taskDetail.taskId)"
          type="left-circle"
          theme="filled"
        />
        <span
          :style="isDesktop() ? 'font-size:25px' : 'font-size:12px'"
          v-if="taskList.findIndex(item => item.taskId == taskDetail.taskId)"
          >{{ taskDetail.chapterSequenceNo - 1 }}</span
        >
      </div>
      <div class="right" v-if="taskList && taskList.length" style="display: flex;flex-direction: column;">
        <a-icon
          @click="next"
          :style="isDesktop() ? 'font-size:30px' : 'font-size:15px'"
          v-if="taskList.findIndex(item => item.taskId == taskDetail.taskId) != taskList.length - 1"
          type="right-circle"
          theme="filled"
        />
        <span :style="isDesktop() ? 'font-size:25px' : 'font-size:12px'">{{
          taskDetail.chapterSequenceNo - 0 + 1
        }}</span>
      </div>
      <a-row>
        <a-breadcrumb separator=">">
          <a-breadcrumb-item style="font-size:16px">
            <span style="cursor: pointer;" @click="skip">我的任务</span>
          </a-breadcrumb-item>
          <a-breadcrumb-item style="font-size:16px">
            <span style="cursor: pointer;">上传完成稿</span>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-if="taskDetail.showName" href="" style="font-size:16px">
            {{
              taskDetail.chapterSequenceNo
                ? taskDetail.showName + ' - ' + taskDetail.chapterSequenceNo + '-' + taskDetail.stageName
                : taskDetail.showName + '-' + taskDetail.stageName
            }}
          </a-breadcrumb-item>
        </a-breadcrumb>
      </a-row>
      <a-row class="content">
        <a-col :span="12">
          <div class="left-button-list">
            <a-button-group style="display:flex">
              <a-button
                icon="unordered-list"
                @click="changeViewType('list')"
                :style="viewType == 'list' ? 'width:45px;z-index:9' : 'width:45px'"
                :type="viewType == 'list' ? 'primary' : ''"
                :ghost="viewType == 'list' ? true : false"
              ></a-button>
              <a-button
                @click="changeViewType('widthView')"
                :style="viewType == 'widthView' ? 'width:45px;z-index:9' : 'width:45px'"
                :type="viewType == 'widthView' ? 'primary' : ''"
                :ghost="viewType == 'widthView' ? true : false"
              >
                <span
                  class="iconfont icon-dengkuanfenbu icon"
                  :style="viewType == 'widthView' ? `color:${this.color} !important` : 'color:black !important;'"
                ></span>
              </a-button>
              <a-button
                @click="changeViewType('heightView')"
                :style="viewType == 'heightView' ? 'width:45px;z-index:9' : 'width:45px'"
                :type="viewType == 'heightView' ? 'primary' : ''"
                :ghost="viewType == 'heightView' ? true : false"
              >
                <span
                  class="iconfont icon-denggaofenbu icon"
                  :style="viewType == 'heightView' ? `color:${this.color} !important` : 'color:black !important;'"
                ></span>
              </a-button>
            </a-button-group>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="right-button-list">
            <!--             <a-button icon="scan" class="mr-3" type="primary">
              OCR
            </a-button> -->

            <a-dropdown
              transitionName=""
              v-if="
                ((taskDetail.taskStatus != '等待' && taskDetail.taskStatus != '驳回') ||
                  (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId)) &&
                  taskDetail.applyReview
              "
              :trigger="['click']"
            >
              <a-button
                @mouseover="checkColor = color"
                class="mr-3 ant-btn"
                @mouseout="checkColor = 'rgba(0, 0, 0, 0.45)'"
              >
                <checklist
                  style="position:relative;top:2px;left:3px"
                  theme="outline"
                  size="21"
                  :fill="checkColor"
                  :strokeWidth="3"
                />
              </a-button>
              <a-menu slot="overlay">
                <a-menu-item key="0" @click="goToFatchStraw(true)">
                  加载锁定文件
                </a-menu-item>
                <a-menu-item key="1" @click="goToFatchStraw(false)">
                  不加载锁定文件
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <actions-tasks-btn
              :propsParams="taskDetail"
              :loadData="reloadData"
              :taskList="taskDetail.taskList"
            ></actions-tasks-btn>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="visible ? 15 : 24" class="left-content">
          <div class="left-content-button-list">
            <div>
              <defaultSizeUpload
                v-if="
                  taskDetail.applyReview &&
                    (taskDetail.stageName == '制作' ||
                      taskDetail.stageName == '制作修改' ||
                      taskDetail.stageName.indexOf('建模') > -1 ||
                      taskDetail.stageName.indexOf('精草') > -1 ||
                      taskDetail.stageName.indexOf('勾线') > -1 ||
                      taskDetail.stageName.indexOf('上色') > -1 ||
                      taskDetail.stageName.indexOf('后期') > -1) &&
                    (taskDetail.taskStatus == '可开始' || (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId))
                "
                btnText="上传完成稿"
                :upType="defaultType"
                :taskDetail="taskDetail"
                @startProgress="startProgress"
                @changeProgress="changeProgress"
                @changeTransProgress="changeTransProgress"
                @endProgress="endProgress"
                btnType="finish"
                :sizeList="sizeList"
                :loadData="getRightTableData"
                :showFileList="rightDataSource"
                @replaceImg="replaceImg"
                saveUrl="/finish/manuscript"
                ref="defaultSizeUpload"
              />
              <defaultSizeUpload
                v-if="
                  taskDetail.applyReview &&
                    taskDetail.departName &&
                    taskDetail.departName.indexOf('日语部') > -1 &&
                    (taskDetail.stageName == '制作' ||
                      taskDetail.stageName == '制作修改' ||
                      taskDetail.stageName == '建模' ||
                      taskDetail.stageName == '精草' ||
                      taskDetail.stageName == '勾线' ||
                      taskDetail.stageName == '上色' ||
                      taskDetail.stageName == '后期') &&
                    (taskDetail.taskStatus == '可开始' || (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId))
                "
                btnText="上传切片"
                :upType="defaultType"
                :taskDetail="taskDetail"
                :sectionTotalSize="sectionTotalSize"
                @startProgress="startProgress"
                @changeProgress="changeProgress"
                @changeTransProgress="changeTransProgress"
                @endProgress="endProgress"
                btnType="finish"
                :sizeList="sizeList"
                :loadData="getRightTableData"
                :showFileList="rightDataSource"
                @replaceImg="replaceImg"
                saveUrl="/finish/manuscript"
                ref="defaultSizeUploadjpg"
              />
              <defaultSizeUploadTmb
                v-if="
                  taskDetail.applyReview &&
                    taskDetail.departName &&
                    taskDetail.departName.indexOf('日语部') > -1 &&
                    (taskDetail.stageName == '制作' ||
                      taskDetail.stageName == '制作修改' ||
                      taskDetail.stageName == '建模' ||
                      taskDetail.stageName == '精草' ||
                      taskDetail.stageName == '勾线' ||
                      taskDetail.stageName == '上色' ||
                      taskDetail.stageName == '后期')
                "
                btnText="上传tmb"
                :upType="defaultType"
                :taskDetail="taskDetail"
                @startProgress="startProgress"
                @changeProgress="changeProgress"
                @changeTransProgress="changeTransProgress"
                @endProgress="endProgress"
                btnType="finish"
                :sizeList="sizeList"
                :loadData="getRightTableData"
                :showFileList="rightDataSource"
                @replaceImg="replaceImg"
                saveUrl="/finish/manuscript"
                ref="defaultSizeUploadTmb"
              />
            </div>
            <div v-if="!visible">
              <a-button
                :disabled="
                  !selectedJpgRowKeys.length ||
                    (selectionJpgRows.length && selectionJpgRows.every(item => item.wasLock)) ||
                    selectionJpgRows.some(item => item.isSectionFile || item.isTmbFile)
                "
                @click="batchLock(true)"
                v-if="checkPermission('uploadSubmit:lock')"
                class="mr-3"
              >
                锁定
              </a-button>
              <a-button
                :disabled="
                  !selectedJpgRowKeys.length ||
                    (selectionJpgRows.length && selectionJpgRows.every(item => !item.wasLock)) ||
                    selectionJpgRows.some(item => item.isSectionFile || item.isTmbFile)
                "
                @click="batchLock(false)"
                v-if="checkPermission('uploadSubmit:lock')"
                :class="
                  taskDetail.taskStatus == '可开始' || (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId)
                    ? 'mr-3'
                    : ''
                "
              >
                解锁
              </a-button>
              <a-button
                v-if="
                  checkPermission('uploadSubmit:replace') &&
                    (taskDetail.taskStatus == '可开始' ||
                      (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId)) &&
                    taskDetail.departName &&
                    taskDetail.departName.indexOf('日语部') == -1
                "
                :disabled="!rightDataSource.length"
                @click="changeAll"
                class="mr-3"
              >
                替换
              </a-button>
              <!--             <a-button
              v-if="checkPermission('uploadSubmit:lock')"
              :disabled="!selectedJpgRowKeys.length||selectionJpgRows.some(item=>item.wasLock)"
              @click="changeAll"
              class="mr-3"
            >
              锁定
            </a-button>
            <a-button
              v-if="checkPermission('uploadSubmit:lock')"
              :disabled="!selectedJpgRowKeys.length||selectionJpgRows.some(item=>!item.wasLock)"
              @click="changeAll"
              class="mr-3"
            >
              解锁
            </a-button> -->
              <a-button
                :disabled="!selectedJpgRowKeys.length || selectionJpgRows.some(item => item.wasLock)"
                @click="deleteJpgImg"
                v-if="
                  checkPermission('uploadSubmit:delete') &&
                    (taskDetail.taskStatus == '可开始' || (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId))
                "
                icon="delete"
                type="danger"
              >
                删除
              </a-button>
            </div>
          </div>
          <div class="upload-title" v-show="taskDetail.departName && taskDetail.departName.indexOf('日语部') > -1">
            完成稿
          </div>
          <div
            class="upload-title"
            style="left:calc(50% - 9px)"
            v-show="taskDetail.departName && taskDetail.departName.indexOf('日语部') > -1"
          >
            切片
          </div>
          <div
            class="left-content-list box"
            :style="{
              width:
                taskDetail.departName && taskDetail.departName.indexOf('日语部') == -1 ? '100%' : 'calc(50% - 10px)',
              marginRight: taskDetail.departName && taskDetail.departName.indexOf('日语部') == -1 ? '' : '20px',
              marginTop: taskDetail.departName && taskDetail.departName.indexOf('日语部') == -1 ? '25px' : '65px',
              height:
                taskDetail.departName && taskDetail.departName.indexOf('日语部') == -1
                  ? ' calc(100vh - 375px)'
                  : ' calc(100vh - 415px)'
            }"
            @scroll="boxScroll"
            @mousedown.stop="handleMouseDown"
            @drop.stop="handleDropTop"
            @dragenter.prevent
            @dragover.prevent
            @mouseup="changeDisUpload"
          >
            <div
              class="mask"
              v-show="is_show_mask && viewType != 'list'"
              :style="'width:' + mask_width + 'left:' + mask_left + 'height:' + mask_height + 'top:' + mask_top"
            ></div>
            <a-upload-dragger
              name="file"
              v-if="viewType == 'list'"
              :disabled="disUpload"
              :multiple="true"
              :showUploadList="false"
              :before-upload="(file, fileList) => draggerBeforeUpload(file, fileList)"
              :accept="
                '.zip,' +
                  (defaultType.indexOf('JPG') > -1 ? '.jpg,' : '') +
                  (defaultType.indexOf('PSD') > -1 ? '.psd,' : '') +
                  (defaultType.indexOf('PNG') > -1 ? '.png,' : '')
              "
            >
              <a-table
                size="middle"
                rowKey="id"
                v-if="rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length"
                :columns="columns"
                :dataSource="rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile)"
                @change="handleLeftTableChange"
                :class="rightDataSource.length ? 'have-data' : ''"
                :customRow="visible ? customRowLeft : () => {}"
                :pagination="false"
                :loading="jpgLoading"
                :scroll="{ x: 'max-content', y: 'calc(100vh - 505px)' }"
                :rowSelection="rowSelection"
                ><span slot="fileName" style="white-space:pre-line" slot-scope="text, record"
                  >{{ record.fileName }}<a-icon style="margin-left:5px" v-if="record.wasLock" type="lock" />
                </span>
                <span slot="cc" slot-scope="text, record">
                  <div v-if="record.fileSizeList && record.fileSizeList.length">
                    <div v-for="(f, index) in record.fileSizeList" :key="index">
                      {{ f }}
                    </div>
                  </div>
                  <span v-else>{{ record.width + '×' + record.height }}</span>
                </span>
                <span slot="fileLength" slot-scope="text, record">
                  {{ (record.fileLength / 1024 / 1024).toFixed(2) + 'M' }}
                </span>
                <div
                  slot="lastModified"
                  slot-scope="text, record"
                  style="display:flex;justify-content: space-between;align-items: center;"
                >
                  <div>{{ record.lastModified }}</div>
                  <div v-if="record.showCheck" style="margin-right:15px">
                    <div class="check-icon">
                      <a-icon @click.stop="cancelReplaceTable(record)" type="close-circle" theme="filled" />
                    </div>
                    <div class="check-icon">
                      <a-icon @click.stop="keepReplaceTable(record)" type="check-circle" theme="filled" />
                    </div>
                  </div>
                </div>
              </a-table>
              <div v-else class="prog-div" style="position:relative;height:calc(100vh - 505px)">
                <span v-if="!rightShow" style="position:relative;top:45px;">拖拽到此处上传</span>
                <a-spin v-else />
              </div>
              <div
                v-if="
                  viewType == 'list' && rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length
                "
                style="line-height:50px;padding-left:16px;text-align:left"
              >
                已选择：{{ selectionJpgRows.filter(item => !item.isSectionFile && !item.isTmbFile).length }}项，共{{
                  rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length
                }}条。
              </div></a-upload-dragger
            >
            <div v-else-if="rightShow" class="prog-div">
              <Progress :width="300" :progress="rightNum" :strokeWidth="10" :showInfo="true" />
            </div>
            <a-spin :spinning="jpgLoading" v-else-if="viewType == 'widthView'" style="minHeight:100%">
              <a-upload-dragger
                name="file"
                :disabled="disUpload"
                :multiple="true"
                :showUploadList="false"
                :before-upload="(file, fileList) => draggerBeforeUpload(file, fileList)"
                :accept="
                  '.zip,' +
                    (defaultType.indexOf('JPG') > -1 ? '.jpg,' : '') +
                    (defaultType.indexOf('PSD') > -1 ? '.psd,' : '') +
                    (defaultType.indexOf('PNG') > -1 ? '.png,' : '')
                "
              >
                <div
                  v-show="!rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length"
                  class="prog-div"
                >
                  <!-- <a-empty :image="simpleImage" style="margin:0 auto" /> -->
                  <span>拖拽到此处上传</span>
                </div>
                <draggable
                  v-show="rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length"
                  dragClass="drag-img"
                  ghostClass="ghost"
                  class="img-list"
                  :draggable="false"
                  :animation="300"
                  filter=".not-allowed"
                  v-model="rightDataSource"
                  @start="drag = true"
                  @end="leftDragend"
                >
                  <div
                    @click.stop="selectRightImg(item, index)"
                    @dblclick="imgDblclick(item, index)"
                    :class="item.wasLock ? 'img-div' : 'img-div'"
                    v-for="(item, index) in rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile)"
                    :key="index"
                  >
                    <div :style="{ background: !item.wasLock ? '' : 'rgba(0,0,0,0.5)' }">
                      <img
                        :class="!item.wasLock ? '' : 'was-lock-img'"
                        :src="item.filePath"
                        :style="'width:70px;' + `${item.borderColor ? 'border:2px solid ' + item.borderColor : ''}`"
                        alt=""
                      />
                    </div>
                    <div
                      :id="item.id"
                      :style="{ backgroundColor: item.wasLock ? '' : '' }"
                      :class="item.checked ? 'select-img img-content' : 'no-select-img img-content'"
                    ></div>
                    <div
                      v-if="!replaceDom || replaceDom.target.id != item.id || !dragChangeValue || item.wasLock"
                      class="img-action"
                      :style="{ justifyContent: !item.wasLock ? '' : '' }"
                      @mouseover="iconColor = '#030303'"
                      @mouseout="iconColor = '#acacac'"
                    >
                      <div
                        v-if="checkPermission('fatchStraw:view')"
                        @mouseover="viewColor = color"
                        @mouseout="viewColor = ''"
                      >
                        <a-tooltip transitionName="" v-if="checkPermission('fatchStraw:view')">
                          <template slot="title">
                            从当前文件查看
                          </template>
                          <a-icon
                            @click.stop="viewClick(item)"
                            v-if="checkPermission('fatchStraw:view')"
                            type="eye"
                            :style="{ color: viewColor ? viewColor : iconColor }"
                          />
                        </a-tooltip>
                      </div>
                      <div
                        v-if="
                          !item.wasLock &&
                            (taskDetail.taskStatus == '可开始' ||
                              (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId))
                        "
                        @mouseover="replaceColor = color"
                        @mouseout="replaceColor = ''"
                      >
                        <a-upload
                          name="file"
                          accept=".jpg,.psd,.zip"
                          :showUploadList="false"
                          :multiple="true"
                          :before-upload="(file, fileList) => beforeUploadOnly(file, fileList, item)"
                          :custom-request="handleRequest"
                        >
                          <a-tooltip transitionName="">
                            <template slot="title">
                              替换
                            </template>
                            <change
                              v-if="checkPermission('uploadSubmit:replace')"
                              theme="outline"
                              size="18"
                              :fill="replaceColor ? replaceColor : iconColor"
                              :strokeWidth="3"
                            />
                          </a-tooltip>
                        </a-upload>
                      </div>
                      <div @mouseover="lockColor = color" @mouseout="lockColor = ''">
                        <a-tooltip transitionName="" v-if="!item.wasLock && checkPermission('uploadSubmit:lock')">
                          <template slot="title">
                            锁定
                          </template>
                          <a-icon
                            type="lock"
                            @click="lock(item)"
                            :style="{ color: lockColor ? lockColor : iconColor }"
                          />
                        </a-tooltip>
                        <a-tooltip transitionName="" v-else-if="checkPermission('uploadSubmit:lock') && item.wasLock">
                          <template slot="title">
                            解锁
                          </template>
                          <a-icon
                            type="unlock"
                            @click="unlock(item)"
                            :style="{ color: item.wasLock ? color : lockColor ? lockColor : iconColor }"
                          />
                        </a-tooltip>
                      </div>
                    </div>
                    <div v-else class="img-action" style="opacity:1;z-index:1;">
                      <div class="check-icon">
                        <a-icon @click.stop="cancelReplace" type="close-circle" theme="filled" />
                      </div>
                      <div class="check-icon">
                        <a-icon @click.stop="keepReplace" type="check-circle" theme="filled" />
                      </div>
                    </div>
                    <div class="img-count" v-if="item.modifiedCount">{{ item.modifiedCount }}改稿</div>
                    <div class="img-name">{{ item.fileName }}</div>
                  </div>
                </draggable>
              </a-upload-dragger>
            </a-spin>
            <a-spin :spinning="jpgLoading" v-else-if="viewType == 'heightView'" style="minHeight:100%">
              <a-upload-dragger
                name="file"
                :disabled="disUpload"
                :multiple="true"
                :showUploadList="false"
                :before-upload="(file, fileList) => draggerBeforeUpload(file, fileList)"
                :accept="
                  '.zip,' +
                    (defaultType.indexOf('JPG') > -1 ? '.jpg,' : '') +
                    (defaultType.indexOf('PSD') > -1 ? '.psd,' : '') +
                    (defaultType.indexOf('PNG') > -1 ? '.png,' : '')
                "
              >
                <div
                  v-show="!rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length"
                  class="prog-div"
                >
                  <span>拖拽到此处上传</span>
                </div>
                <draggable
                  v-show="rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile).length"
                  :draggable="false"
                  dragClass="drag-img"
                  ghostClass="ghost"
                  class="img-list"
                  :animation="300"
                  v-model="rightDataSource"
                  filter=".not-allowed"
                  @start="drag = true"
                  @end="leftDragend"
                >
                  <div
                    @click.stop="selectRightImg(item, index)"
                    @dblclick="imgDblclick(item, index)"
                    :class="item.wasLock ? 'img-div-height' : 'img-div-height'"
                    v-for="(item, index) in rightDataSource.filter(item => !item.isSectionFile && !item.isTmbFile)"
                    :key="item.id"
                  >
                    <div :style="{ background: !item.wasLock ? '' : 'rgba(0,0,0,0.5)', maxWidth: '100%' }">
                      <img
                        :src="item.filePath"
                        :style="'height:350px;' + `${item.borderColor ? 'border:2px solid ' + item.borderColor : ''}`"
                        alt=""
                        :class="!item.wasLock ? '' : 'was-lock-img'"
                      />
                    </div>
                    <div
                      :id="item.id"
                      :style="{ backgroundColor: item.wasLock ? '' : '' }"
                      :class="item.checked ? 'select-img img-content' : 'no-select-img img-content'"
                    ></div>
                    <div
                      v-if="!replaceDom || replaceDom.target.id != item.id || !dragChangeValue || item.wasLock"
                      class="img-action"
                      :style="{ justifyContent: !item.wasLock ? '' : '' }"
                      @mouseover="iconColor = '#030303'"
                      @mouseout="iconColor = '#acacac'"
                    >
                      <div
                        v-if="checkPermission('fatchStraw:view')"
                        @mouseover="viewColor = color"
                        @mouseout="viewColor = ''"
                      >
                        <a-tooltip transitionName="" v-if="checkPermission('fatchStraw:view')">
                          <template slot="title">
                            从当前文件查看
                          </template>
                          <a-icon
                            @click.stop="viewClick(item)"
                            v-if="checkPermission('fatchStraw:view')"
                            type="eye"
                            :style="{ color: viewColor ? viewColor : iconColor }"
                          />
                        </a-tooltip>
                      </div>
                      <div
                        v-if="
                          !item.wasLock &&
                            (taskDetail.taskStatus == '可开始' ||
                              (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId))
                        "
                        @mouseover="replaceColor = color"
                        @mouseout="replaceColor = ''"
                      >
                        <a-upload
                          name="file"
                          accept=".jpg,.psd,.zip"
                          :showUploadList="false"
                          :multiple="true"
                          :before-upload="(file, fileList) => beforeUploadOnly(file, fileList, item)"
                          :custom-request="handleRequest"
                        >
                          <a-tooltip transitionName="">
                            <template slot="title">
                              替换
                            </template>
                            <change
                              v-if="checkPermission('uploadSubmit:replace')"
                              theme="outline"
                              size="18"
                              :fill="replaceColor ? replaceColor : iconColor"
                              :strokeWidth="3"
                            />
                          </a-tooltip>
                        </a-upload>
                      </div>
                      <div @mouseover="lockColor = color" @mouseout="lockColor = ''">
                        <a-tooltip transitionName="" v-if="!item.wasLock && checkPermission('uploadSubmit:lock')">
                          <template slot="title">
                            锁定
                          </template>
                          <a-icon
                            type="lock"
                            @click="lock(item)"
                            :style="{ color: lockColor ? lockColor : iconColor }"
                          />
                        </a-tooltip>
                        <a-tooltip transitionName="" v-else-if="checkPermission('uploadSubmit:lock') && item.wasLock">
                          <template slot="title">
                            解锁
                          </template>
                          <a-icon
                            type="unlock"
                            @click="unlock(item)"
                            :style="{ color: item.wasLock ? color : lockColor ? lockColor : iconColor }"
                          />
                        </a-tooltip>
                      </div>
                    </div>
                    <div v-else class="img-action" style="opacity:1;z-index:1;">
                      <div class="check-icon">
                        <a-icon @click.stop="cancelReplace" type="close-circle" theme="filled" />
                      </div>
                      <div class="check-icon">
                        <a-icon @click.stop="keepReplace" type="check-circle" theme="filled" />
                      </div>
                    </div>
                    <div class="img-count" v-if="item.modifiedCount">{{ item.modifiedCount }}改稿</div>
                    <div class="img-name">{{ item.fileName }}</div>
                  </div>
                </draggable>
              </a-upload-dragger>
            </a-spin>
            <!--             <div class="select-num" v-if="viewType == 'list'">
              已选择：{{ selectedJpgRowKeys.length }}项，共{{ rightDataSource.length }}项
            </div> -->
          </div>
          <div
            class="left-content-list box"
            :style="{
              width:
                taskDetail.departName && taskDetail.departName.indexOf('日语部') == -1 ? '100%' : 'calc(50% - 10px)',
              marginTop: taskDetail.departName && taskDetail.departName.indexOf('日语部') == -1 ? '25px' : '65px',
              height:
                taskDetail.departName && taskDetail.departName.indexOf('日语部') == -1
                  ? ' calc(100vh - 375px)'
                  : ' calc(100vh - 415px)'
            }"
            @scroll="boxScroll"
            @mousedown.stop="handleMouseDownTwo"
            @drop.stop="handleDropTop"
            @dragenter.prevent
            @dragover.prevent
            @mouseup="changeDisUpload"
            v-if="taskDetail.departName && taskDetail.departName.indexOf('日语部') > -1"
          >
            <div
              class="mask_two"
              v-show="is_show_mask_two && viewType != 'list'"
              :style="'width:' + mask_width + 'left:' + mask_left + 'height:' + mask_height + 'top:' + mask_top"
            ></div>
            <a-upload-dragger
              name="file"
              v-if="viewType == 'list'"
              :disabled="disUpload"
              :multiple="true"
              :showUploadList="false"
              :before-upload="(file, fileList) => draggerBeforeUploadJpg(file, fileList)"
              :accept="
                '.zip,' +
                  (defaultType.indexOf('JPG') > -1 ? '.jpg,' : '') +
                  (defaultType.indexOf('PSD') > -1 ? '.psd,' : '') +
                  (defaultType.indexOf('PNG') > -1 ? '.png,' : '')
              "
            >
              <a-table
                size="middle"
                v-if="rightDataSource.filter(item => item.isSectionFile || item.isTmbFile).length"
                rowKey="id"
                :columns="columns"
                :dataSource="rightDataSource.filter(item => item.isSectionFile || item.isTmbFile)"
                @change="handleLeftTableChange"
                :class="rightDataSource.length ? 'have-data' : ''"
                :customRow="visible ? customRowLeft : () => {}"
                :pagination="false"
                :loading="jpgLoading"
                :scroll="{ x: 'max-content', y: 'calc(100vh - 505px)' }"
                :rowSelection="rowSelection"
                ><span slot="fileName" style="white-space:pre-line" slot-scope="text, record"
                  >{{ record.fileName }}<a-icon style="margin-left:5px" v-if="record.wasLock" type="lock" />
                </span>
                <span slot="cc" slot-scope="text, record">
                  <div v-if="record.fileSizeList && record.fileSizeList.length">
                    <div v-for="(f, index) in record.fileSizeList" :key="index">
                      {{ f }}
                    </div>
                  </div>
                  <span v-else>{{ record.width + '×' + record.height }}</span>
                </span>
                <span slot="fileLength" slot-scope="text, record">
                  {{ (record.fileLength / 1024 / 1024).toFixed(2) + 'M' }}
                </span>
                <div
                  slot="lastModified"
                  slot-scope="text, record"
                  style="display:flex;justify-content: space-between;align-items: center;"
                >
                  <div>{{ record.lastModified }}</div>
                  <div v-if="record.showCheck" style="margin-right:15px">
                    <div class="check-icon">
                      <a-icon @click.stop="cancelReplaceTable(record)" type="close-circle" theme="filled" />
                    </div>
                    <div class="check-icon">
                      <a-icon @click.stop="keepReplaceTable(record)" type="check-circle" theme="filled" />
                    </div>
                  </div>
                </div>
              </a-table>
              <div v-else class="prog-div" style="position:relative;height:calc(100vh - 505px)">
                <span v-if="!rightShow" style="position:relative;top:45px;">拖拽到此处上传</span>
                <a-spin v-else />
              </div>
              <div
                v-if="
                  viewType == 'list' &&
                    taskDetail.departName &&
                    taskDetail.departName.indexOf('日语部') > -1 &&
                    rightDataSource.filter(item => item.isSectionFile || item.isTmbFile).length
                "
                style="line-height:50px;padding-left:16px;text-align:left"
              >
                已选择：{{ selectionJpgRows.filter(item => item.isSectionFile || item.isTmbFile).length }}项，共{{
                  rightDataSource.filter(item => item.isSectionFile || item.isTmbFile).length
                }}条。
              </div>
            </a-upload-dragger>
            <div v-else-if="rightShow" class="prog-div">
              <Progress :width="300" :progress="rightNum" :strokeWidth="10" :showInfo="true" />
            </div>
            <a-spin :spinning="jpgLoading" v-else-if="viewType == 'widthView'">
              <a-upload-dragger
                name="file"
                :disabled="disUpload"
                :multiple="true"
                :showUploadList="false"
                :before-upload="(file, fileList) => draggerBeforeUploadJpg(file, fileList)"
                :accept="
                  '.zip,' +
                    (defaultType.indexOf('JPG') > -1 ? '.jpg,' : '') +
                    (defaultType.indexOf('PSD') > -1 ? '.psd,' : '') +
                    (defaultType.indexOf('PNG') > -1 ? '.png,' : '')
                "
              >
                <div
                  v-show="!rightDataSource.filter(item => item.isSectionFile || item.isTmbFile).length"
                  class="prog-div"
                >
                  <!-- <a-empty :image="simpleImage" style="margin:0 auto" /> -->
                  <span>拖拽到此处上传</span>
                </div>
                <draggable
                  v-show="
                    taskDetail.departName &&
                      taskDetail.departName.indexOf('日语部') > -1 &&
                      rightDataSource.filter(item => item.isSectionFile || item.isTmbFile).length
                  "
                  dragClass="drag-img"
                  ghostClass="ghost"
                  class="img-list"
                  :draggable="false"
                  :animation="300"
                  filter=".not-allowed"
                  v-model="rightDataSource"
                  @start="drag = true"
                  @end="leftDragend"
                >
                  <div
                    @click.stop="selectRightImg(item, index)"
                    @dblclick="imgDblclick(item, index)"
                    :class="item.wasLock ? 'img-div' : 'img-div'"
                    v-for="(item, index) in rightDataSource.filter(item => item.isSectionFile || item.isTmbFile)"
                    :key="item.id"
                  >
                    <div :style="{ background: !item.wasLock ? '' : 'rgba(0,0,0,0.5)' }">
                      <img
                        :class="!item.wasLock ? '' : 'was-lock-img'"
                        :src="item.filePath"
                        :style="'width:70px;' + `${item.borderColor ? 'border:2px solid ' + item.borderColor : ''}`"
                        alt=""
                      />
                    </div>
                    <div
                      :id="item.id"
                      :style="{ backgroundColor: item.wasLock ? '' : '' }"
                      :class="item.checked ? 'select-img img-content' : 'no-select-img img-content'"
                    ></div>
                    <div
                      v-if="!replaceDom || replaceDom.target.id != item.id || !dragChangeValue || item.wasLock"
                      class="img-action"
                      :style="{ justifyContent: !item.wasLock ? '' : '' }"
                      @mouseover="iconColor = '#030303'"
                      @mouseout="iconColor = '#acacac'"
                    >
                      <div
                        v-if="checkPermission('fatchStraw:view')"
                        @mouseover="viewColor = color"
                        @mouseout="viewColor = ''"
                      >
                        <a-tooltip transitionName="" v-if="checkPermission('fatchStraw:view')">
                          <template slot="title">
                            从当前文件查看
                          </template>
                          <a-icon
                            @click.stop="viewClick(item)"
                            v-if="checkPermission('fatchStraw:view')"
                            type="eye"
                            :style="{ color: viewColor ? viewColor : iconColor }"
                          />
                        </a-tooltip>
                      </div>
                      <div
                        v-if="
                          !item.wasLock &&
                            (taskDetail.taskStatus == '可开始' ||
                              (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId))
                        "
                        @mouseover="replaceColor = color"
                        @mouseout="replaceColor = ''"
                      >
                        <a-upload
                          name="file"
                          accept=".jpg,.psd,.zip"
                          :showUploadList="false"
                          :multiple="true"
                          :before-upload="(file, fileList) => beforeUploadOnly(file, fileList, item)"
                          :custom-request="handleRequest"
                        >
                          <a-tooltip transitionName="">
                            <template slot="title">
                              替换
                            </template>
                            <change
                              v-if="checkPermission('uploadSubmit:replace')"
                              theme="outline"
                              size="18"
                              :fill="replaceColor ? replaceColor : iconColor"
                              :strokeWidth="3"
                            />
                          </a-tooltip>
                        </a-upload>
                      </div>
                      <div @mouseover="lockColor = color" @mouseout="lockColor = ''">
                        <a-tooltip transitionName="" v-if="checkPermission('uploadSubmit:lock') && item.wasLock">
                          <template slot="title">
                            解锁
                          </template>
                          <a-icon
                            type="unlock"
                            @click="unlock(item)"
                            :style="{ color: item.wasLock ? color : lockColor ? lockColor : iconColor }"
                          />
                        </a-tooltip>
                      </div>
                    </div>
                    <div v-else class="img-action" style="opacity:1;z-index:1;">
                      <div class="check-icon">
                        <a-icon @click.stop="cancelReplace" type="close-circle" theme="filled" />
                      </div>
                      <div class="check-icon">
                        <a-icon @click.stop="keepReplace" type="check-circle" theme="filled" />
                      </div>
                    </div>
                    <div class="img-count" v-if="item.modifiedCount">{{ item.modifiedCount }}改稿</div>
                    <div class="img-name">{{ item.fileName }}</div>
                  </div>
                </draggable>
              </a-upload-dragger>
            </a-spin>
            <a-spin :spinning="jpgLoading" v-else-if="viewType == 'heightView'">
              <a-upload-dragger
                name="file"
                :disabled="disUpload"
                :multiple="true"
                :showUploadList="false"
                :before-upload="(file, fileList) => draggerBeforeUploadJpg(file, fileList)"
                :accept="
                  '.zip,' +
                    (defaultType.indexOf('JPG') > -1 ? '.jpg,' : '') +
                    (defaultType.indexOf('PSD') > -1 ? '.psd,' : '') +
                    (defaultType.indexOf('PNG') > -1 ? '.png,' : '')
                "
              >
                <div
                  v-show="!rightDataSource.filter(item => item.isSectionFile || item.isTmbFile).length"
                  class="prog-div"
                >
                  <!-- <a-empty :image="simpleImage" style="margin:0 auto" /> -->
                  <span>拖拽到此处上传</span>
                </div>
                <draggable
                  v-show="
                    taskDetail.departName &&
                      taskDetail.departName.indexOf('日语部') > -1 &&
                      rightDataSource.filter(item => item.isSectionFile || item.isTmbFile).length
                  "
                  :draggable="false"
                  dragClass="drag-img"
                  ghostClass="ghost"
                  class="img-list"
                  :animation="300"
                  v-model="rightDataSource"
                  filter=".not-allowed"
                  @start="drag = true"
                  @end="leftDragend"
                >
                  <div
                    @click.stop="selectRightImg(item, index)"
                    @dblclick="imgDblclick(item, index)"
                    :class="item.wasLock ? 'img-div-height' : 'img-div-height'"
                    v-for="(item, index) in rightDataSource.filter(item => item.isSectionFile || item.isTmbFile)"
                    :key="item.id"
                  >
                    <div :style="{ background: !item.wasLock ? '' : 'rgba(0,0,0,0.5)', maxWidth: '100%' }">
                      <img
                        :src="item.filePath"
                        :style="
                          'height:350px;max-width:100%;' +
                            `${item.borderColor ? 'border:2px solid ' + item.borderColor : ''}`
                        "
                        alt=""
                        :class="!item.wasLock ? '' : 'was-lock-img'"
                      />
                    </div>
                    <div
                      :id="item.id"
                      :style="{ backgroundColor: item.wasLock ? '' : '' }"
                      :class="item.checked ? 'select-img img-content' : 'no-select-img img-content'"
                    ></div>
                    <div
                      v-if="!replaceDom || replaceDom.target.id != item.id || !dragChangeValue || item.wasLock"
                      class="img-action"
                      :style="{ justifyContent: !item.wasLock ? '' : '' }"
                      @mouseover="iconColor = '#030303'"
                      @mouseout="iconColor = '#acacac'"
                    >
                      <div
                        v-if="checkPermission('fatchStraw:view')"
                        @mouseover="viewColor = color"
                        @mouseout="viewColor = ''"
                      >
                        <a-tooltip transitionName="" v-if="checkPermission('fatchStraw:view')">
                          <template slot="title">
                            从当前文件查看
                          </template>
                          <a-icon
                            @click.stop="viewClick(item)"
                            v-if="checkPermission('fatchStraw:view')"
                            type="eye"
                            :style="{ color: viewColor ? viewColor : iconColor }"
                          />
                        </a-tooltip>
                      </div>
                      <div
                        v-if="
                          !item.wasLock &&
                            (taskDetail.taskStatus == '可开始' ||
                              (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId))
                        "
                        @mouseover="replaceColor = color"
                        @mouseout="replaceColor = ''"
                      >
                        <a-upload
                          name="file"
                          accept=".jpg,.psd,.zip"
                          :showUploadList="false"
                          :multiple="true"
                          :before-upload="(file, fileList) => beforeUploadOnly(file, fileList, item)"
                          :custom-request="handleRequest"
                        >
                          <a-tooltip transitionName="">
                            <template slot="title">
                              替换
                            </template>
                            <change
                              v-if="checkPermission('uploadSubmit:replace')"
                              theme="outline"
                              size="18"
                              :fill="replaceColor ? replaceColor : iconColor"
                              :strokeWidth="3"
                            />
                          </a-tooltip>
                        </a-upload>
                      </div>
                      <div @mouseover="lockColor = color" @mouseout="lockColor = ''">
                        <!--                       <a-tooltip transitionName="" v-if="!item.wasLock && checkPermission('uploadSubmit:lock')">
                        <template slot="title">
                          锁定
                        </template>
                        <a-icon type="lock" @click="lock(item)" :style="{ color: lockColor ? lockColor : iconColor }" />
                      </a-tooltip> -->
                        <a-tooltip transitionName="" v-if="checkPermission('uploadSubmit:lock') && item.wasLock">
                          <template slot="title">
                            解锁
                          </template>
                          <a-icon
                            type="unlock"
                            @click="unlock(item)"
                            :style="{ color: item.wasLock ? color : lockColor ? lockColor : iconColor }"
                          />
                        </a-tooltip>
                      </div>
                    </div>
                    <div v-else class="img-action" style="opacity:1;z-index:1;">
                      <div class="check-icon">
                        <a-icon @click.stop="cancelReplace" type="close-circle" theme="filled" />
                      </div>
                      <div class="check-icon">
                        <a-icon @click.stop="keepReplace" type="check-circle" theme="filled" />
                      </div>
                    </div>
                    <div class="img-count" v-if="item.modifiedCount">{{ item.modifiedCount }}改稿</div>
                    <div class="img-name">{{ item.fileName }}</div>
                  </div>
                </draggable>
              </a-upload-dragger>
            </a-spin>
          </div>
        </a-col>
        <a-col :span="visible ? 9 : 0" class="right-content" v-if="visible">
          <div class="left-content-button-list">
            <div></div>
            <div
              v-if="taskDetail.taskStatus == '可开始' || (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId)"
            >
              <a-button
                :disabled="
                  !selectedJpgRowKeys.length ||
                    (selectionJpgRows.length && selectionJpgRows.every(item => item.wasLock)) ||
                    selectionJpgRows.some(item => item.isSectionFile || item.isTmbFile)
                "
                @click="batchLock(true)"
                v-if="checkPermission('uploadSubmit:lock')"
                class="mr-3"
              >
                锁定
              </a-button>
              <a-button
                :disabled="
                  !selectedJpgRowKeys.length ||
                    (selectionJpgRows.length && selectionJpgRows.every(item => !item.wasLock)) ||
                    selectionJpgRows.some(item => item.isSectionFile || item.isTmbFile)
                "
                @click="batchLock(false)"
                v-if="checkPermission('uploadSubmit:lock')"
                class="mr-3"
              >
                解锁
              </a-button>
              <a-button
                v-if="
                  checkPermission('uploadSubmit:replace') &&
                    taskDetail.departName &&
                    taskDetail.departName.indexOf('日语部') == -1
                "
                :disabled="!rightDataSource.length"
                @click="changeAll"
                class="mr-3"
              >
                替换
              </a-button>
              <!--             <a-button
              v-if="checkPermission('uploadSubmit:lock')"
              :disabled="!selectedJpgRowKeys.length||selectionJpgRows.some(item=>item.wasLock)"
              @click="changeAll"
              class="mr-3"
            >
              锁定
            </a-button>
            <a-button
              v-if="checkPermission('uploadSubmit:lock')"
              :disabled="!selectedJpgRowKeys.length||selectionJpgRows.some(item=>!item.wasLock)"
              @click="changeAll"
              class="mr-3"
            >
              解锁
            </a-button> -->
              <a-button
                :disabled="!selectedJpgRowKeys.length || selectionJpgRows.some(item => item.wasLock)"
                @click="deleteJpgImg"
                v-if="checkPermission('uploadSubmit:delete')"
                icon="delete"
                type="danger"
              >
                删除
              </a-button>
            </div>
          </div>
          <div class="left-content-list">
            <div style="height:100%;width:100%;">
              <a-upload-dragger
                name="file"
                :accept="'.zip,.' + defaultType"
                :showUploadList="false"
                :multiple="true"
                :before-upload="beforeUpload"
                :custom-request="handleRequest"
                v-if="taskDetail.departName && taskDetail.departName.indexOf('日语部') == -1"
              >
                <a-spin :spinning="loading" style="height:100%">
                  <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                  <div v-if="!changeFileList.length" style="height:100%;display:flex;align-items:center">
                    <div style="flex:1">
                      <p class="ant-upload-drag-icon">
                        <a-icon type="inbox" />
                      </p>
                      <p class="ant-upload-text">
                        点击或将文件拖拽到这里拼接或替换
                      </p>
                    </div>
                  </div>
                  <div v-else class="upload-list">
                    <div v-if="viewType == 'list'" style="height:100%">
                      <a-table
                        size="middle"
                        rowKey="id"
                        :columns="columnsRight"
                        @change="handleRihgtTableChange"
                        :dataSource="changeFileList"
                        :customRow="customRow"
                        :class="changeFileList.length ? 'have-data' : ''"
                        :rowSelection="{
                          selectedRowKeys: selectedRightRowKeys,
                          onChange: onRightSelectChange,
                          columnWidth: 45
                        }"
                        :pagination="false"
                        :scroll="{ x: 'max-content', y: 'calc(100vh - 460px)' }"
                      >
                        <span slot="cc" slot-scope="text, record">
                          <div v-if="record.fileSizeList && record.fileSizeList.length">
                            <div v-for="(f, index) in record.fileSizeList" :key="index">
                              {{ f }}
                            </div>
                          </div>
                          <span v-else>{{ record.width + '×' + record.height }}</span> </span
                        ><span slot="fileName" style="white-space:pre-line" slot-scope="text, record"
                          >{{ record.fileName }}
                        </span>
                        <span slot="fileLength" slot-scope="text, record">
                          {{ (record.fileLength / 1024 / 1024).toFixed(2) + 'M' }}
                        </span>
                      </a-table>
                    </div>
                    <div v-else-if="viewType == 'widthView'">
                      <div
                        @click.stop="selectChangeImg(index)"
                        @dragend.stop="e => dragend(e, item)"
                        class="img-div"
                        v-for="(item, index) in changeFileList"
                        :key="index"
                        :draggable="item.checked"
                      >
                        <img :src="item.filePath" :style="'width:70px;'" alt="" />
                        <div :class="item.checked ? 'select-img' : 'no-select-img'"></div>
                        <div class="img-name">{{ item && item.fileName }}</div>
                      </div>
                    </div>
                    <div v-else-if="viewType == 'heightView'">
                      <div
                        @click.stop="selectChangeImg(index)"
                        class="img-div-height"
                        :draggable="item.checked"
                        @dragend.stop="e => dragend(e, item)"
                        v-for="(item, index) in changeFileList"
                        :key="index"
                      >
                        <img :src="item.filePath" style="max-height:350px" alt="" />
                        <div :class="item.checked ? 'select-img' : 'no-select-img'"></div>
                        <div class="img-name">{{ item.fileName }}</div>
                      </div>
                    </div>
                  </div>
                </a-spin>
              </a-upload-dragger>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <playvideo-modal ref="videoModal" :width="imagePlayWidth" />
  </a-spin>
</template>

<script>
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { getAction, postAction, deleteAction } from '@/api/manage'
import actionsTasksBtn from '@/views/system/modules/mytasks/actionsTasksBtn'
import draggable from 'vuedraggable'
import Progress from '@/components/progress'
import { checkPermission } from '@/utils/hasPermission'
import { Empty } from 'ant-design-vue'
import { FileConversion, Change } from '@icon-park/vue'
import { handleArrayParams } from '@/utils/util'
import defaultSizeUpload from '@/components/default-size-upload'
import defaultSizeUploadTmb from '@/components/default-size-upload-tmb'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import * as imageConversion from 'image-conversion'
import { changeDpiBlob } from '@/utils/changeDpi.js'
import playvideoModal from '@/components/playvideo-upload-modal'
import html2canvas from 'html2canvas'
import PSD from 'psd.js'
import { putAction } from '../../../../../api/manage'
import { mixinDevice } from '@/utils/mixin.js'
import { Checklist } from '@icon-park/vue'
const JSZip = require('jszip')
export default {
  mixins: [UploadFileToOssMixins, mixinDevice],
  components: {
    Checklist,
    actionsTasksBtn,
    draggable,
    Progress,
    Change,
    FileConversion,
    defaultSizeUpload,
    playvideoModal,
    defaultSizeUploadTmb
  },
  data() {
    return {
      viewColor: '',
      lockColor: '',
      replaceColor: '',
      imagePlayWidth: '1000px',
      checkColor: 'rgba(0, 0, 0, 0.55)',
      visible: false,
      psdLoading: false,
      jpgLoading: false,
      is_show_mask_two: false,
      sectionSingleSize: 0,
      sectionTotalSize: 0,
      psdPagination: {
        current: 1,
        pageSize: 50,
        defaultPageSize: 50,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      },
      jpgPagination: {
        current: 1,
        pageSize: 50,
        defaultPageSize: 50,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      },
      params: {
        ...this.$route.params,
        record: this.$route.query.record
      },
      rightDataSource: [],
      leftDataSource: [
        {
          id: '1',
          sort: '1',
          fileName: 'abcdegf.psd',
          cc: '1920*1080',
          fbl: '300',
          fileSize: '50MB',
          time: '2023-3-16 14:00:00'
        }
      ],
      columns: [
        {
          title: '名称',
          dataIndex: 'fileName',
          sorter: (a, b) => a.index - b.index,
          scopedSlots: { customRender: 'fileName' },
          width: 140
        },
        {
          title: '尺寸',
          dataIndex: 'cc',
          sorter: (a, b) => a.height - b.height,
          scopedSlots: { customRender: 'cc' },
          width: 105
        },
        {
          title: '分辨率',
          dataIndex: 'dpi',
          sorter: (a, b) => a.dpi - b.dpi,
          width: 105
        },
        {
          title: '大小',
          dataIndex: 'fileLength',
          sorter: (a, b) => a.fileLength - b.fileLength,
          scopedSlots: { customRender: 'fileLength' },
          width: 105
        },
        {
          title: '修改时间',
          dataIndex: 'lastModified',
          sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
          scopedSlots: { customRender: 'lastModified' },
          width: 200
        }
      ],
      columnsRight: [
        {
          title: '名称',
          dataIndex: 'fileName',
          sorter: (a, b) => a.fileName.localeCompare(b.fileName),
          width: 180
        },
        {
          title: '尺寸',
          dataIndex: 'cc',
          scopedSlots: { customRender: 'cc' },
          sorter: (a, b) => a.height - b.height,
          width: 160
        },
        {
          title: '大小',
          dataIndex: 'fileLength',
          sorter: (a, b) => a.fileLength - b.fileLength,
          scopedSlots: { customRender: 'fileLength' },
          width: 150
        }
      ],
      url: {
        list: ''
      },
      spinning: false,
      taskDetail: {
        productionName: '',
        platformName: '',
        chapterSequenceNo: ''
      },
      viewType: 'heightView',
      selectedPsdRowKeys: [],
      selectionPsdRows: [],
      selectedJpgRowKeys: [],
      selectionJpgRows: [],
      sizeList: [],
      color: '',
      iconColor: '#acacac',
      backColor: '',
      fileRightList: [],
      imgRights: [],
      rightArr: [],
      fileLeftList: [],
      taskList: [],
      imgLefts: [],
      defaultHeight: 0,
      defaultMaxHeight: 0,
      defaultMinHeight: 0,
      defaultWidth: 0,
      defaultType: 'JPG',
      upType: 'JPG',
      defaultDpi: 300,
      leftArr: [],
      rightNum: 0,
      rightTransNum: 1,
      leftTransNum: 1,
      rightShow: false,
      changeFileList: [],
      changeFileListOnly: [],
      loading: false,
      leftNum: 0,
      leftShow: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      psdFiles: [],
      psdPromise: [],
      fileList: [],
      ossUploadDatas: {},
      upList: [],
      selectedRightRowKeys: [],
      selectionRightRows: [],
      userInfo: null,
      dragChangeValue: null,
      replaceDom: null,
      sourceObj: null,
      disUpload: false,
      isOnly: false,
      replaceOnlyId: null,
      isAdmin: false,
      isWindows: true,
      dragStartIndex: 0,
      color: '#000000',
      start_x: 0,
      start_y: 0,
      end_x: 0,
      end_y: 0,
      is_show_mask: false,
      box_screen_left: 0,
      box_screen_top: 0,
      select_list: [],
      scrollTop: 0,
      isDrag: false
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color,
        '--back-color': this.backColor
      }
    },
    mask_width() {
      return `${Math.abs(this.end_x - this.start_x)}px;`
    },
    mask_top() {
      return `${Math.min(this.start_y, this.end_y) - this.box_screen_top}px;`
    },
    mask_left() {
      return `${Math.min(this.start_x, this.end_x) - this.box_screen_left}px;`
    },
    mask_height() {
      return `${Math.abs(this.end_y - this.start_y)}px;`
    },
    rowSelection() {
      const _this = this
      const { selectedJpgRowKeys, selectionRows, disRowTitles } = this
      return {
        selectedRowKeys: this.selectedJpgRowKeys,
        columnWidth: 45,
        onChange: (selectedRowKeys, selectionRows) => {
          this.selectedJpgRowKeys = selectedRowKeys
          this.selectionJpgRows = this.rightDataSource.filter(item =>
            this.selectedJpgRowKeys.some(key => key == item.id)
          )
          this.rightDataSource.forEach(item => {
            if (this.selectedJpgRowKeys.some(key => key == item.id)) {
              item.checked = true
            } else {
              item.checked = false
            }
          })
        }
        /*         getCheckboxProps: record => ({
          props: {
            disabled: record.wasLock
          }
        }) */
      }
    }
  },
  created() {
    if (sessionStorage.getItem('submit_left_sort')) {
      let sortData = JSON.parse(sessionStorage.getItem('submit_left_sort'))
      this.columns.forEach(c => {
        if (c.dataIndex == sortData.columnKey) {
          c.defaultSortOrder = sortData.order
        }
      })
    }
    if (sessionStorage.getItem('submit_right_sort')) {
      let sortData = JSON.parse(sessionStorage.getItem('submit_right_sort'))
      this.columnsRight.forEach(c => {
        if (c.dataIndex == sortData.columnKey) {
          c.defaultSortOrder = sortData.order
        }
      })
    }
    this.getAlldata()
    this.userInfo = Vue.ls.get(USER_INFO)
    this.isAdmin = this.userInfo.roles.some(item => {
      return item.roleCode == 'admin' || item.roleCode == 'boss'
    })
    this.viewType = this.$route.query.viewType || 'heightView'
    this.isWindows = navigator.userAgent.toLowerCase().indexOf('windows') > -1
    this.keyDown()
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.backColor = rule.style.backgroundColor
          }
        }
      }
    }, 100)
    window.addEventListener('mousewheel', this.handleScroll, { passive: false })
  },
  beforeDestroy() {
    window.removeEventListener('mousewheel', this.handleScroll)
  },
  methods: {
    boxScroll(e) {
      this.scrollTop = e.target.scrollTop
    },
    handleScroll(e) {
      // 判断是不是按下ctrl键
      if (e.ctrlKey) {
        // 取消浏览器默认的放大缩小网页行为
        // 判断是向上滚动还是向下滚动
        if (this.$refs.videoModal && this.$refs.videoModal.visible) {
          e.preventDefault()
          if (e.deltaY > 0) {
            this.$refs.videoModal.scale('reduce')
          } else {
            this.$refs.videoModal.scale('amp')
          }
        }
      }
    },
    keyDown() {
      document.onkeydown = e => {
        if (this.isWindows) {
          if (e.ctrlKey && e.key == '+') {
            if (this.$refs.videoModal && this.$refs.videoModal.visible) {
              e.preventDefault()
              let images = Array.from(document.getElementsByClassName('video-image'))
              images.forEach(img => {
                img.style.width = img.clientWidth + 10 + 'px'
              })
              if (images[0].clientWidth + 10 > 1000) {
                this.imagePlayWidth = images[0].clientWidth + 10 + 'px'
              }
            }
          } else if (e.ctrlKey && e.key == '-') {
            if (this.$refs.videoModal && this.$refs.videoModal.visible) {
              e.preventDefault()
              let images = Array.from(document.getElementsByClassName('video-image'))
              if (images[0].clientWidth > 50) {
                images.forEach(img => {
                  img.style.width = img.clientWidth - 10 + 'px'
                })
              }
            }
          }
        } else {
          if (e.metaKey && e.key == '+') {
            if (this.$refs.videoModal && this.$refs.videoModal.visible) {
              e.preventDefault()
              let images = Array.from(document.getElementsByClassName('video-image'))
              images.forEach(img => {
                img.style.width = img.clientWidth + 10 + 'px'
              })
              if (images[0].clientWidth + 10 > 1000) {
                this.imagePlayWidth = images[0].clientWidth + 10 + 'px'
              }
            }
          } else if (e.metaKey && e.key == '-') {
            if (this.$refs.videoModal && this.$refs.videoModal.visible) {
              e.preventDefault()
              let images = Array.from(document.getElementsByClassName('video-image'))
              if (images[0].clientWidth > 50) {
                images.forEach(img => {
                  img.style.width = img.clientWidth - 10 + 'px'
                })
              }
            }
          }
        }
      }
    },
    handleMouseDown(event) {
      if (event.target.tagName == 'svg' || event.target.nodeName == 'path') return
      if (!event.target.className || (event.target.className && event.target.className.indexOf('img-content') > -1))
        return
      if (this.viewType == 'list') return
      this.is_show_mask = true
      this.start_x = event.clientX - 280
      this.start_y = event.clientY - 367 + this.scrollTop
      this.end_x = event.clientX - 280
      this.end_y = event.clientY - 367 + this.scrollTop
      this.rightDataSource.forEach(item => {
        item.checked = false
      })
      this.selectedJpgRowKeys = []
      this.selectedRowKeys = []
      document.body.addEventListener('mousemove', this.handleMouseMove)
      document.body.addEventListener('mouseup', this.handleMouseUp)
    },
    handleMouseDownTwo(event) {
      if (event.target.tagName == 'svg' || event.target.nodeName == 'path') return
      if (!event.target.className || (event.target.className && event.target.className.indexOf('img-content') > -1))
        return
      if (this.viewType == 'list') return
      this.is_show_mask_two = true
      this.start_x = event.clientX - 1077
      this.start_y = event.clientY - 367 + this.scrollTop
      this.end_x = event.clientX - 1077
      this.end_y = event.clientY - 367 + this.scrollTop
      this.rightDataSource.forEach(item => {
        item.checked = false
      })
      this.selectedJpgRowKeys = []
      this.selectedRowKeys = []
      document.body.addEventListener('mousemove', this.handleMouseMoveTwo)
      document.body.addEventListener('mouseup', this.handleMouseUpTwo)
    },
    handleMouseMove(event) {
      if (this.isDrag) {
        this.start_x = 0
        this.start_y = 0
        this.end_x = 0
        this.end_y = 0
        return
      }
      this.end_x = event.clientX - 280
      this.end_y = event.clientY - 367 + this.scrollTop
    },
    handleMouseMoveTwo(event) {
      if (this.isDrag) {
        this.start_x = 0
        this.start_y = 0
        this.end_x = 0
        this.end_y = 0
        return
      }
      this.end_x = event.clientX - 1077
      this.end_y = event.clientY - 367 + this.scrollTop
    },
    resSetXY() {
      this.start_x = 0
      this.start_y = 0
      this.end_x = 0
      this.end_y = 0
    },
    handleMouseUp() {
      document.body.removeEventListener('mousemove', this.handleMouseMove)
      document.body.removeEventListener('mouseup', this.handleMouseUp)
      this.is_show_mask = false
      this.handleDomSelect()
      this.resSetXY()
      this.changeDisUpload()
    },
    handleMouseUpTwo() {
      document.body.removeEventListener('mousemove', this.handleMouseMoveTwo)
      document.body.removeEventListener('mouseup', this.handleMouseUpTwo)
      this.is_show_mask_two = false
      this.handleDomSelectTwo()
      this.resSetXY()
      this.changeDisUpload()
    },
    changeDisUpload(e) {
      this.disUpload = true
      this.$nextTick(() => {
        this.disUpload = false
      })
    },
    handleDomSelect() {
      const dom_mask = window.document.querySelector('.mask')
      const rect_select = dom_mask.getClientRects()[0]
      const add_list = []
      const del_list = []
      let doms = Array.from(document.getElementsByClassName('img-content'))
      doms?.forEach(dom => {
        if (dom.getClientRects()[0]) {
          if (
            ((dom.getClientRects()[0].x > rect_select.x &&
              dom.getClientRects()[0].x < rect_select.x + rect_select.width) ||
              (rect_select.x > dom.getClientRects()[0].x &&
                rect_select.x < dom.getClientRects()[0].x + dom.getClientRects()[0].width)) &&
            ((dom.getClientRects()[0].y > rect_select.y &&
              dom.getClientRects()[0].y < rect_select.y + rect_select.height) ||
              (rect_select.y > dom.getClientRects()[0].y &&
                rect_select.y < dom.getClientRects()[0].y + dom.getClientRects()[0].height))
          ) {
            if (this.selectedJpgRowKeys.some(item => item == dom.id)) {
              this.selectedJpgRowKeys = this.selectedJpgRowKeys.filter(item => item != dom.id)
              let row = this.rightDataSource.find(item => item.id == dom.id)
              row.checked = false
              this.selectionJpgRows = this.selectionJpgRows.filter(item => item.id != dom.id)
            } else {
              this.selectedJpgRowKeys.push(dom.id)
              let row = this.rightDataSource.find(item => item.id == dom.id)
              row.checked = true
              this.selectionJpgRows.push(row)
            }
          }
        }
      })
    },
    handleDomSelectTwo() {
      const dom_mask = window.document.querySelector('.mask_two')
      const rect_select = dom_mask.getClientRects()[0]
      const add_list = []
      const del_list = []
      let doms = Array.from(document.getElementsByClassName('img-content'))
      doms?.forEach(dom => {
        if (dom.getClientRects()[0]) {
          if (
            ((dom.getClientRects()[0].x > rect_select.x &&
              dom.getClientRects()[0].x < rect_select.x + rect_select.width) ||
              (rect_select.x > dom.getClientRects()[0].x &&
                rect_select.x < dom.getClientRects()[0].x + dom.getClientRects()[0].width)) &&
            ((dom.getClientRects()[0].y > rect_select.y &&
              dom.getClientRects()[0].y < rect_select.y + rect_select.height) ||
              (rect_select.y > dom.getClientRects()[0].y &&
                rect_select.y < dom.getClientRects()[0].y + dom.getClientRects()[0].height))
          ) {
            if (this.selectedJpgRowKeys.some(item => item == dom.id)) {
              this.selectedJpgRowKeys = this.selectedJpgRowKeys.filter(item => item != dom.id)
              let row = this.rightDataSource.find(item => item.id == dom.id)
              row.checked = false
              this.selectionJpgRows = this.selectionJpgRows.filter(item => item.id != dom.id)
            } else {
              this.selectedJpgRowKeys.push(dom.id)
              let row = this.rightDataSource.find(item => item.id == dom.id)
              row.checked = true
              this.selectionJpgRows.push(row)
            }
          }
        }
      })
    },
    handleRihgtTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        let data = { columnKey: sorter.columnKey, order: sorter.order }
        sessionStorage.setItem('submit_right_sort', JSON.stringify(data))
      } else {
        sessionStorage.setItem('submit_right_sort', '')
      }
    },
    handleLeftTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        let data = { columnKey: sorter.columnKey, order: sorter.order }
        sessionStorage.setItem('submit_left_sort', JSON.stringify(data))
      } else {
        sessionStorage.setItem('submit_left_sort', '')
      }
    },
    imgDblclick(item, idx) {
      let index = this.rightDataSource.findIndex(r => r.id == item.id)
      let urls = []
      if (
        this.rightDataSource[index - 1] &&
        this.rightDataSource[index - 1].isSectionFile == item.isSectionFile &&
        this.rightDataSource[index - 1].isTmbFile == item.isTmbFile
      ) {
        urls.push({
          url: this.rightDataSource[index - 1].filePath,
          selected: false,
          name: this.rightDataSource[index - 1].fileName
        })
      }
      if (this.rightDataSource[index]) {
        urls.push({
          url: this.rightDataSource[index].filePath,
          selected: true,
          name: this.rightDataSource[index].fileName
        })
      }
      if (
        this.rightDataSource[index + 1] &&
        this.rightDataSource[index + 1].isSectionFile == item.isSectionFile &&
        this.rightDataSource[index + 1].isTmbFile == item.isTmbFile
      ) {
        urls.push({
          url: this.rightDataSource[index + 1].filePath,
          selected: false,
          name: this.rightDataSource[index + 1].fileName
        })
      }
      this.$refs.videoModal.open(urls)
    },
    goToFatchStraw(loadLock) {
      /* window.open(
        `/fatchStraw/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0` + (loadLock ? '?loadLock=true' : '')
      ) */
      const newUrl =
        `/fatchStraw/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0` + (loadLock ? '?loadLock=true' : '')
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    customRowLeftTwo(record, index) {
      var that = this
      return {
        // FIXME: draggable： true 不生效还不晓得是什么原因，先使用鼠标移入事件设置目标行的draggable属性
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer'
        },
        on: {
          // 鼠标移入
          dblclick: event => {
            this.imgDblclick(record, index)
          },
          mouseenter: event => {
            // 兼容IE
            var ev = event || window.event
            ev.target.draggable = true
          },
          // 开始拖拽
          dragstart: event => {
            // 兼容IE
            var ev = event || window.event
            if (record.wasLock) {
              ev.preventDefault()
            }
            // 阻止冒泡
            ev.stopPropagation()
            // 得到源目标数据
            // console.log(that);
            that.dragStartIndex = index
            that.sourceObj = record
          },
          // 拖动元素经过的元素
          dragover: event => {
            // 兼容 IE
            var ev = event || window.event
            // 阻止默认行为
            ev.preventDefault()
          },
          // 鼠标松开
          drop: event => {
            // 兼容IE
            var ev = event || window.event
            // 阻止冒泡
            ev.stopPropagation()
            // 注意=>这里会调换数据,记得注释
            let _data = JSON.parse(JSON.stringify(that.rightDataSource))
            /* _data[that.dragStartIndex] = that.leftDataSource[index] */
            _data[index] = that.rightDataSource[that.dragStartIndex]
            if (that.dragStartIndex < index) {
              for (let i = that.dragStartIndex; i < index; i++) {
                if (that.rightDataSource[i + 1]) {
                  _data[i] = that.rightDataSource[i + 1]
                }
              }
            } else {
              for (let i = index; i < that.dragStartIndex; i++) {
                if (that.rightDataSource[i + 1]) {
                  _data[i + 1] = that.rightDataSource[i]
                }
              }
            }
            that.rightDataSource = _data
            that.leftDragend()
          }
        }
      }
    },
    checkPermission(type) {
      return checkPermission(type)
    },
    async leftDragend() {
      this.drag = false
      let data = {
        chapterId: this.taskDetail.chapterId,
        dataList: this.rightDataSource
      }
      data.taskId = this.taskDetail.taskId
      await putAction('/finish/manuscript', data)
    },
    async lock(item) {
      let data = {
        chapterId: this.taskDetail.chapterId,
        dataList: [
          {
            fileName: item.fileName,
            filePath: item.filePath,
            fileType: item.fileType,
            wasSuccess: true,
            dpi: item.defaultDpi,
            wasLock: true,
            height: item.height,
            width: item.width,
            fileLength: item.fileLength,
            id: item.id
          }
        ]
      }
      data.taskId = this.taskDetail.taskId
      const res = await putAction('/finish/manuscript', data)
      if (res.code == 200) {
        this.getRightTableData()
      }
    },
    async prev() {
      if (!this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId)) return
      this.params.taskId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 1
      ].taskId
      this.params.chapterId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 1
      ].chapterId
      const record = this.taskList[this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 1]
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${this.taskDetail.productName +
          '-' +
          record.chapterOrder +
          '-' +
          this.taskDetail.stageName}&type=上传完成稿&viewType=${this.viewType}`
      })
    },
    async next() {
      if (this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) == this.taskList.length - 1) return
      this.params.taskId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 0 + 1
      ].taskId
      this.params.chapterId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 0 + 1
      ].chapterId
      const record = this.taskList[this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 0 + 1]
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${this.taskDetail.productName +
          '-' +
          record.chapterOrder +
          '-' +
          this.taskDetail.stageName}&type=上传完成稿&viewType=${this.viewType}`
      })
    },
    async unlock(item) {
      let data = {
        chapterId: this.taskDetail.chapterId,
        dataList: [
          {
            fileName: item.fileName,
            filePath: item.filePath,
            fileType: item.fileType,
            wasSuccess: true,
            dpi: item.defaultDpi,
            wasLock: false,
            height: item.height,
            width: item.width,
            fileLength: item.fileLength,
            id: item.id
          }
        ]
      }
      data.taskId = this.taskDetail.taskId
      const res = await putAction('/finish/manuscript', data)
      if (res.code == 200) {
        this.getRightTableData()
      }
    },
    customRow(record, index) {
      var that = this
      return {
        // FIXME: draggable： true 不生效还不晓得是什么原因，先使用鼠标移入事件设置目标行的draggable属性
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer'
        },
        on: {
          // 鼠标移入
          mouseenter: event => {
            // 兼容IE
            var ev = event || window.event
            ev.target.draggable = true
          },
          // 开始拖拽
          dragstart: event => {
            // 兼容IE
            var ev = event || window.event
            // 阻止冒泡
            ev.stopPropagation()
            if (!that.selectedRightRowKeys.length) {
              ev.preventDefault()
              return
            }
            // 得到源目标数据
            // console.log(that);
            that.sourceObj = record
          },
          // 拖动元素经过的元素
          dragover: event => {
            // 兼容 IE
            var ev = event || window.event
            // 阻止默认行为
          },
          // 鼠标松开
          drop: event => {
            // 兼容IE
            var ev = event || window.event
            // 阻止冒泡
            ev.stopPropagation()
            that.sourceObj = null
            Array.from(document.getElementsByClassName('ant-table-row')).forEach(dom => {
              dom.style.backgroundColor = ''
            })
          }
        }
      }
    },
    customRowLeft(record, index) {
      var that = this
      return {
        // FIXME: draggable： true 不生效还不晓得是什么原因，先使用鼠标移入事件设置目标行的draggable属性
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer'
        },
        on: {
          dblclick: event => {
            this.imgDblclick(record, index)
          },
          dragstart: event => {
            // 兼容IE
            var ev = event || window.event
            ev.preventDefault()
          },
          dragover: event => {
            // 兼容 IE
            var ev = event || window.event
            // 阻止默认行为
            ev.preventDefault()
          },
          dragenter: event => {
            // 兼容 IE
            var ev = event || window.event
            // 阻止默认行为
            ev.preventDefault()
          },
          dragover: event => {
            if (that.sourceObj) {
              Array.from(document.getElementsByClassName('ant-table-row')).forEach(dom => {
                if (dom.dataset.rowKey == record.id) {
                  dom.style.backgroundColor = '#f0f8ff'
                } else {
                  dom.style.backgroundColor = ''
                }
              })
            }
          },
          // 鼠标松开
          drop: event => {
            // 兼容IE
            if (record.wasLock) return
            var ev = event || window.event
            // 阻止冒泡
            that.sourceObj = null
            record.showCheck = true
            Array.from(document.getElementsByClassName('ant-table-row')).forEach(dom => {
              if (dom.dataset.rowKey == record.id) {
                dom.style.backgroundColor = '#f0f8ff'
              } else {
                dom.style.backgroundColor = ''
              }
            })
            this.$forceUpdate()
            // 得到目标数据
          }
        }
      }
    },
    draggerBeforeUpload(file, fileList) {
      this.$refs.defaultSizeUpload.beforeUpload(file, fileList)
      return false
    },
    draggerBeforeUploadJpg(file, fileList) {
      this.$refs.defaultSizeUploadjpg.beforeUpload(file, fileList)
      return false
    },
    handleDropTop(e) {
      this.replaceDom = e
    },
    cancelReplaceTable(record) {
      record.showCheck = false
      Array.from(document.getElementsByClassName('ant-table-row')).forEach(dom => {
        dom.style.backgroundColor = ''
      })
    },
    cancelReplace() {
      this.replaceDom = null
      this.dragChangeValue = null
    },
    replaceOnly() {
      if (this.changeFileListOnly.length == 1) {
        let file = JSON.parse(JSON.stringify(this.changeFileListOnly[0]))
        this.changeFileListOnly = []
        this.replaceImg(this.replaceOnlyId, file, [file])
      } else if (this.changeFileListOnly.length > 1) {
        let files = JSON.parse(JSON.stringify(this.changeFileListOnly))
        this.changeFileListOnly = []
        this.joint(this.replaceOnlyId, files)
      }
    },
    keepReplaceTable(record) {
      if (this.selectedRightRowKeys.length == 1) {
        let file = JSON.parse(JSON.stringify(this.changeFileList.find(item => item.id == this.selectedRightRowKeys[0])))
        this.changeFileList.splice(
          this.changeFileList.findIndex(item => item.id == file.id),
          1
        )
        this.replaceImg(record.id, file, [file])
      } else if (this.selectedRightRowKeys.length > 1) {
        let files = JSON.parse(
          JSON.stringify(this.changeFileList.filter(item => this.selectedRightRowKeys.some(key => key == item.id)))
        )
        let deleArr = files.map(item => item.id)
        deleArr.forEach((dId, dIndex) => {
          this.changeFileList.splice(
            this.changeFileList.findIndex(item => item.id == dId),
            1
          )
        })
        this.joint(record.id, files)
      }
    },
    keepReplace() {
      if (!this.replaceDom) return
      if (this.replaceDom.target.className.indexOf('select-img') > -1) {
        if (this.selectedRightRowKeys.length == 1) {
          if (this.replaceDom.target.id) {
            let file = JSON.parse(
              JSON.stringify(this.changeFileList.find(item => item.id == this.selectedRightRowKeys[0]))
            )
            this.changeFileList.splice(
              this.changeFileList.findIndex(item => item.id == file.id),
              1
            )
            this.replaceImg(this.replaceDom.target.id, file, [file])
          }
        } else if (this.selectedRightRowKeys.length > 1) {
          let files = JSON.parse(
            JSON.stringify(this.changeFileList.filter(item => this.selectedRightRowKeys.some(key => key == item.id)))
          )
          let deleArr = files.map(item => item.id)
          deleArr.forEach((dId, dIndex) => {
            this.changeFileList.splice(
              this.changeFileList.findIndex(item => item.id == dId),
              1
            )
          })
          this.joint(this.replaceDom.target.id, files)
        }
      }
    },
    beforeUploadTwo(file) {
      console.log('上传之前，检查文件类型')
      return this.ossPolicy(file.name)
    },
    base64ToBlob(dataURI) {
      var base64Arr = dataURI.split(',')
      var imgtype = ''
      var base64String = ''
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1]
        imgtype = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';'))
      }
      // 将base64解码
      var bytes = atob(base64String)
      //var bytes = base64;
      var bytesCode = new ArrayBuffer(bytes.length)
      // 转换为类型化数组
      var byteArray = new Uint8Array(bytesCode)

      // 将base64转换为ascii码
      for (var i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i)
      }

      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], { type: imgtype })
    },
    joint(id, files) {
      const div = document.createElement('div')
      div.style.position = 'absolute'
      document.getElementsByClassName('my-task')[0].appendChild(div)
      let imgs = []
      let fileName = ''
      this.jpgLoading = true
      files.forEach((file, fIdx) => {
        if (fileName) {
          fileName = fileName + '<br>' + file.fileName
        } else {
          fileName = file.fileName
        }
        const childDiv = document.createElement('div')
        const img = document.createElement('img')
        img.src = file.filePath
        /*         if (fIdx < files.length - 1) {
          childDiv.style.borderBottom = '3px dashed orange'
        } */
        childDiv.appendChild(img)
        div.appendChild(childDiv)
        let fileSizeList = ''
        img.onload = () => {
          imgs.push(img)
          if (imgs.length == files.length) {
            imgs.forEach(i => {
              if (fileSizeList) {
                fileSizeList = fileSizeList + ',' + i.width + '×' + i.height
              } else {
                fileSizeList = i.width + '×' + i.height
              }
            })
            html2canvas(div, {
              useCORS: true
            }).then(async canvas => {
              let imgUrl = canvas.toDataURL('image/jpeg')
              div.remove()
              let newBlob = this.base64ToBlob(imgUrl)
              let that = this
              changeDpiBlob(newBlob, that.defaultDpi).then(blob => {
                let newFile = new File([blob], fileName, { type: 'contentType', lastModified: Date.now() })
                setTimeout(async () => {
                  await this.beforeUploadTwo(newFile)
                  let formData = new FormData()
                  for (let key in this.ossUploadData) {
                    formData.append(key, this.ossUploadData[key])
                  }
                  formData.append('file', newFile)
                  postAction(this.ossUploadData.host, formData).then(rest => {
                    let params = this.getSaveFileParams(newFile)
                    let img = new Image()
                    img.src = params.filePath
                    img.onload = function() {
                      let pushFile = {
                        fileName,
                        filePath: params.filePath,
                        dpi: that.defaultDpi,
                        fileSize: fileSizeList,
                        height: this.height,
                        width: this.width,
                        fileLength: newFile.size
                      }
                      that.replaceImg(id, pushFile, files)
                    }
                  })
                }, 0)
              })
            })
          }
        }
      })
    },
    async replaceImg(id, file, files) {
      let splicingManuscriptList = []
      let row = this.rightDataSource.find(item => item.id == id)
      if (row.isSectionFile) {
        this.sectionSingleSize = this.sizeList[0].size.sectionSingleSize || 0
        /* this.sectionTotalSize = this.sizeList[0].size.sectionTotalSize || 0 */
        if (this.sectionSingleSize || this.sectionTotalSize > 0) {
          if (this.sectionSingleSize) {
            if (file.fileLength > this.sectionSingleSize * 1048576) {
              this.$message.error('请检查文件大小，单张最大' + this.sectionSingleSize + 'M')
              this.getRightTableData()
              return
            }
          }
          if (this.sectionTotalSize > 0) {
            if (
              files
                .map(item => item.fileLength)
                .reduce((prev, cur) => {
                  return prev + cur
                }) > this.sectionTotalSize
            ) {
              this.$message.error('请检查文件大小，切片总量超出最大值')
              this.getRightTableData()
              return
            }
          }
        }
      }
      if (row.isTmbFile) {
        this.defaultHeight = this.sizeList[0].size.tmbHeight || 0
        this.defaultWidth = this.sizeList[0].size.tmbWidth || 0
        if (file.height != this.defaultHeight) {
          this.$message.error('高度错误')
          postAction('/oss/delete_oss_file', [file.filePath])
          this.getRightTableData()
          return
        }
        if (file.width != this.defaultWidth) {
          this.$message.error('宽度错误')
          postAction('/oss/delete_oss_file', [file.filePath])
          this.getRightTableData()
          return
        }
      }
      if (!row.isSectionFile && !row.isTmbFile) {
        this.defaultMaxHeight = this.sizeList[0].size.jpgMaxHeight
        this.defaultMinHeight = this.sizeList[0].size.jpgMinHeight
        this.defaultWidth = this.sizeList[0].size.jpgWidth
        this.defaultType = this.sizeList[0].size.jpgFormat
        this.defaultDpi = this.sizeList[0].size.jpgResolution
        if (file.height > this.defaultMaxHeight || file.height < this.defaultMinHeight) {
          this.$message.error('高度错误')
          postAction('/oss/delete_oss_file', [file.filePath])
          this.getRightTableData()
          return
        }
        if (file.width != this.defaultWidth) {
          this.$message.error('宽度错误')
          postAction('/oss/delete_oss_file', [file.filePath])
          this.getRightTableData()
          return
        }
        if ((this.defaultType && this.defaultType.indexOf(this.upType) == -1) || !this.defaultType) {
          this.$message.error(file.fileName + '格式错误')
          postAction('/oss/delete_oss_file', [file.filePath])
          return
        }
      }
      files?.forEach(c => {
        splicingManuscriptList.push({
          fileName: c.fileName,
          filePath: c.filePath,
          dpi: c.dpi,
          height: c.height,
          width: c.width,
          fileLength: c.fileLength
        })
      })
      const res = await postAction('/finish/manuscript/replace', {
        chapterId: this.taskDetail.chapterId,
        taskId: this.taskDetail.taskId,
        replaceId: id,
        finishManuscript: {
          fileName: file.fileName,
          filePath: file.filePath,
          dpi: file.dpi,
          height: file.height,
          width: file.width,
          fileLength: file.fileLength,
          fileSize: file.fileSize ? file.fileSize : '',
          splicingManuscriptList
        },
        stageName: this.taskDetail.stageName
      })
      if (res.code == 200) {
        this.getRightTableData()
      }
    },
    dragend(e, value) {
      this.dragChangeValue = value
    },
    handleRequest() {
      return
    },
    async beforeUploadOnly(file, fileList, data) {
      if (!this.sizeList.length) {
        this.$message.error('未获取到默认尺寸，无法上传图片')
        return
      }
      /*       if (
        this.rightDataSource.length &&
        this.rightDataSource.some(item => item.fileLength == file.size && item.fileName == file.name)
      ) {
        this.$message.warning(file.name + '文件已存在，跳过上传')
        return false
      } */
      if (data.isSectionFile) {
        this.sectionSingleSize = this.sizeList[0].size.sectionSingleSize || 0
        /* this.sectionTotalSize = this.sizeList[0].size.sectionTotalSize || 0 */
        if (this.sectionSingleSize || this.sectionTotalSize > 0) {
          if (this.sectionSingleSize) {
            if (file.size > this.sectionSingleSize * 1048576) {
              this.$message.error('请检查文件大小，单张最大' + this.sectionSingleSize + 'M')
              return
            }
          }
          if (this.sectionTotalSize > 0) {
            if (
              fileList
                .map(item => item.size)
                .reduce((prev, cur) => {
                  return prev + cur
                }) >
              this.sectionTotalSize * 1048576
            ) {
              this.$message.error('请检查文件大小，总量最大' + this.sectionTotalSize + 'M')
              return
            }
          }
        }
      }
      this.defaultMaxHeight = this.sizeList[0].size.jpgMaxHeight
      this.defaultMinHeight = this.sizeList[0].size.jpgMinHeight
      this.defaultWidth = this.sizeList[0].size.jpgWidth
      this.defaultType = this.sizeList[0].size.jpgFormat
      this.defaultDpi = this.sizeList[0].size.jpgResolution
      if (data.isTmbFile) {
        this.defaultHeight = this.sizeList[0].size.tmbHeight || 0
        this.defaultWidth = this.sizeList[0].size.tmbWidth || 0
        this.defaultType = this.sizeList[0].size.tmbFormat
      }
      if (file.name.indexOf('.zip') == -1 && file.name.indexOf('.ZIP') == -1) {
        if (this.defaultType == 'JPG' && file.name.indexOf('.jpg') == -1 && file.name.indexOf('.JPG') == -1) {
          this.$message.error('文件类型错误')
          return
        }
        if (this.defaultType == 'PNG' && file.name.indexOf('.png') == -1 && file.name.indexOf('.PNG') == -1) {
          this.$message.error('文件类型错误')
          return
        }
        if (this.defaultType == 'PSD' && file.name.indexOf('.psd') == -1 && file.name.indexOf('.PSD') == -1) {
          this.$message.error('文件类型错误')
          return
        }
      }
      this.psdFiles = []
      this.psdPromise = []
      fileList.forEach(item => {
        let type = item.name
          .split('.')
          .slice(-1)[0]
          .toLowerCase()
        if (type == 'psd' || type == 'PSD') {
          this.psdFiles.push(item)
        }
      })
      this.loading = true
      this.jpgLoading = true
      this.isOnly = true
      this.replaceOnlyId = data.id
      this.handleUpload({ file })
    },
    async beforeUpload(file, fileList) {
      if (!this.sizeList.length) {
        this.$message.error('未获取到默认尺寸，无法上传图片')
        return
      }
      this.defaultMaxHeight = this.sizeList[0].size.jpgMaxHeight
      this.defaultMinHeight = this.sizeList[0].size.jpgMinHeight
      this.defaultWidth = this.sizeList[0].size.jpgWidth
      this.defaultType = this.sizeList[0].size.jpgFormat
      this.defaultDpi = this.sizeList[0].size.jpgResolution
      this.psdFiles = []
      this.psdPromise = []
      fileList.forEach(item => {
        let type = item.name
          .split('.')
          .slice(-1)[0]
          .toLowerCase()
        if (type == 'psd' || type == 'PSD') {
          this.psdFiles.push(item)
        }
      })
      this.loading = true
      this.handleUpload({ file })
    },
    async handleUpload(info) {
      let file = info.file
      let type = file.name
        .split('.')
        .slice(-1)[0]
        .toLowerCase()
      if (type == 'psd' || type == 'PSD') {
        this.fileList.push(file)
        this.upList.push(file)
        this.psdUpload(file)
      } else if (type == 'jpg' || type == 'JPG') {
        this.fileList.push(file)
        this.upList.push(file)
        this.jpgUpload(file)
      } else if (type == 'zip' || type == 'ZIP') {
        const zip = new JSZip()
        // 解压Zip压缩包，参数默认是二进制
        const zipData = await zip.loadAsync(file)
        this.upList = [...this.upList, ...Object.values(zipData.files).filter(item => !item.dir)]
        let zipFatherNameList = []
        for (let key in zipData.files) {
          if (zipData.files[key].dir) {
            zipFatherNameList.push(key)
          }
        }
        for (let key in zipData.files) {
          if (!zipData.files[key].dir) {
            // 判断是否是目录
            if (key.indexOf('psd') > -1 || key.indexOf('jpg') > -1) {
              this.fileList.push(zipData.files[key])
            }
            if (/\.(xlsx)$/.test(zipData.files[key].name)) {
              // 判断是否是xlsx文件
              let base = await zip.file(zipData.files[key].name).async('base64') // 以base64输出文本内容
              // Base64 转 File 对象
              const result = this.dataURLtoFile(base, zipData.files[key].name)
              /* this.fileList.push(result) */
              console.log(result, '最终解压后的File对象')
            } else {
              if (zipData.files[key].name.split('/').length > 2) {
                this.loading = false
                this.$message.error('压缩文件存在二级目录，请修改层级后上传')
                return false
              }
              const promises = []
              let originName = zipData.files[key].name
              if (zipFatherNameList.some(item => zipData.files[key].name.indexOf(item) > -1)) {
                zipData.files[key].name = zipData.files[key].name.replace(
                  zipFatherNameList.find(item => zipData.files[key].name.indexOf(item) > -1),
                  ''
                )
              }
              let zipType = zipData.files[key].name
                .split('.')
                .slice(-1)[0]
                .toLowerCase()
              let promise = zip.file(originName).async('base64')
              promises.push(promise)
              Promise.all(promises).then(base => {
                const result = this.dataURLtoFile(base, zipData.files[key].name)
                this.ossUploadData = this.ossUploadDatas[result.name]
                this.ossFilePolicy[result.name] = this.ossUploadData
                if (zipType == 'psd' || zipType == 'PSD') {
                  this.psdZipUpload(result)
                } else if (zipType == 'jpg' || zipType == 'JPG') {
                  this.jpgUpload(result)
                } else if (zipType == 'png' || zipType == 'PNG') {
                  this.pngUpload(result)
                }
              })
              console.log(zipData.files[key], '最终解压后的File对象')
            }
          }
        }
      } else if (type == 'png' || type == 'PNG') {
        this.fileList.push(file)
        this.upList.push(file)
        this.pngUpload(file)
      }
    },
    async psdZipUpload(psdFile) {
      let that = this
      let fileSize = psdFile.size
      var url = URL.createObjectURL(psdFile)
      const psd = await PSD.fromURL(url)
      // 图片不大于4m,宽度不大于2000
      let file = this.base64ToFile(psd.image.toBase64().replace('png', that.upType), psdFile.name)
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        // 这里需要计算出图片的长宽
        let img = new Image()
        img.onload = function() {
          file.width = img.width // 获取到width放在了file属性上
          file.height = img.height // 获取到height放在了file属性上
          // console.log(11, file)
          if (!that.sizeList.length) {
            this.$message.error('未获取到默认尺寸，无法上传图片')
            return
          }
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          let compressNum = file.width - that.defaultWidth
          if (compressNum >= 100) {
            imageConversion
              .compressAccurately(file, {
                size: ((file.size * (that.defaultWidth / file.width)) / 1000).toFixed(0),
                width: that.defaultWidth
              })
              .then(res => {
                changeDpiBlob(res, that.defaultDpi).then(blob => {
                  let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                  setTimeout(() => {
                    that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                  }, 0)
                })
                resolve(res)
              })
              .catch(err => {
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.fileList.length) {
                  that.loading = false
                }
                console.log('上传失败789')
                that.$message.error(file.name + '上传失败')
              })
          } else if (compressNum < 100 && compressNum >= 0) {
            imageConversion
              .compressAccurately(file, {
                size: ((file.size * 0.9) / 1000).toFixed(0),
                width: that.defaultWidth
              })
              .then(res => {
                changeDpiBlob(res, that.defaultDpi).then(blob => {
                  let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                  setTimeout(() => {
                    that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                  }, 0)
                })
                resolve(res)
              })
              .catch(err => {
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.fileList.length) {
                  that.loading = false
                }
                console.log('上传失败890')
                that.$message.error(file.name + '上传失败')
              })
          } else {
            imageConversion
              .compressAccurately(file, {
                size: ((file.size * 0.9) / 1000).toFixed(0),
                width: that.defaultWidth
              })
              .then(res => {
                changeDpiBlob(res, that.defaultDpi).then(blob => {
                  let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                  setTimeout(() => {
                    that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                  }, 0)
                })
                resolve(res)
              })
              .catch(err => {
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.fileList.length) {
                  that.loading = false
                }
                console.log('上传失败890')
                that.$message.error(file.name + '上传失败')
              })
          }
        }
        // 需要把图片赋值
        img.src = _URL.createObjectURL(file)
      })
    },
    dataURLtoFile(dataURL, fileName, fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      /**
       * 注意：【不同文件不同类型】，例如【图片类型】就是`data:image/png;base64,${dataURL}`.split(',')
       * 下面的是【excel文件(.xlsx尾缀)】的文件类型拼接，一个完整的 base64 应该
       * 是这样的,例如： data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
       */

      const arr = `data:${fileType};base64,${dataURL}`.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      let blob = new File([u8arr], fileName, { type: mime })
      return blob
    },
    async jpgUpload(file) {
      let that = this
      let data = new FormData()
      data.append('files', file)
      /* const rest = await postAction('/kt/translate/getDpi', data) */
      let rest = {
        code: 200,
        data: [this.defaultDpi]
      }
      if (rest.code == 200 && rest.data[0] == this.defaultDpi) {
        let fileSize = file.size
        return new Promise((resolve, reject) => {
          let _URL = window.URL || window.webkitURL
          let isLt2M = file.size / 1024 / 1024 > 4 // 判定图片大小是否小于4MB
          // 这里需要计算出图片的长宽
          let img = new Image()
          img.onload = function() {
            file.width = img.width // 获取到width放在了file属性上
            file.height = img.height // 获取到height放在了file属性上
            let valid = img.width > 0 // 图片宽度大于2000
            // console.log(11, file)
            if (!that.sizeList.length) {
              this.$message.error('未获取到默认尺寸，无法上传图片')
              return
            }
            // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
            if (valid || isLt2M) {
              let compressNum = file.width - that.defaultWidth
              if (compressNum >= 100) {
                imageConversion
                  .compressAccurately(file, {
                    size: ((file.size * (that.defaultWidth / file.width)) / 1000).toFixed(0),
                    width: that.defaultWidth
                  })
                  .then(res => {
                    //修改dpi
                    changeDpiBlob(res, that.defaultDpi).then(blob => {
                      let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                      setTimeout(() => {
                        that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                      }, 0)
                    })

                    resolve(res)
                  })
                  .catch(err => {
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    if (!that.fileList.length) {
                      that.loading = false
                    }
                    console.log('上传失败234')
                    that.$message.error(file.name + '上传失败')
                  })
              } else if (compressNum < 100 && compressNum >= 0) {
                imageConversion
                  .compressAccurately(file, {
                    size: ((file.size * 0.9) / 1000).toFixed(0),
                    width: that.defaultWidth
                  })
                  .then(res => {
                    //修改dpi
                    changeDpiBlob(res, that.defaultDpi).then(blob => {
                      let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                      setTimeout(() => {
                        that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                      }, 0)
                    })

                    resolve(res)
                  })
                  .catch(err => {
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    if (!that.fileList.length) {
                      that.loading = false
                    }
                    console.log('上传失败456')
                    that.$message.error(file.name + '上传失败')
                  })
              } else {
                imageConversion
                  .compressAccurately(file, {
                    size: ((file.size * 0.9) / 1000).toFixed(0),
                    width: file.width
                  })
                  .then(res => {
                    changeDpiBlob(res, that.defaultDpi).then(blob => {
                      let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                      setTimeout(() => {
                        that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                      }, 0)
                    })

                    resolve(res)
                  })
                  .catch(err => {
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    if (!that.fileList.length) {
                      that.loading = false
                    }
                    console.log('上传失败456')
                    that.$message.error(file.name + '上传失败')
                  })
              }
            } else resolve(file)
          }
          // 需要把图片赋值
          img.src = _URL.createObjectURL(file)
        })
      } else {
        this.$message.error(file.name + '，分辨率错误')
        that.fileList.splice(
          that.fileList.findIndex(item => item.name == file.name),
          1
        )
        that.changeProgress(true)
        if (!that.fileList.length) {
          that.endProgress()
          that.loading = false
        }
        that.avatarLoading = false
        that.spinning = false
      }
    },
    async pngUpload(file) {
      let that = this
      let data = new FormData()
      data.append('files', file)
      /*  const rest = await postAction('/kt/translate/getDpi', data) */
      let rest = {
        code: 200,
        data: [this.defaultDpi]
      }
      if (rest.code == 200 && rest.data[0] == this.defaultDpi) {
        let fileSize = file.size
        return new Promise((resolve, reject) => {
          let _URL = window.URL || window.webkitURL
          let isLt2M = file.size / 1024 / 1024 > 4 // 判定图片大小是否小于4MB
          // 这里需要计算出图片的长宽
          let img = new Image()
          img.onload = function() {
            file.width = img.width // 获取到width放在了file属性上
            file.height = img.height // 获取到height放在了file属性上
            let valid = img.width > 0 // 图片宽度大于2000
            // console.log(11, file)
            if (!that.sizeList.length) {
              this.$message.error('未获取到默认尺寸，无法上传图片')
              return
            }
            // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
            if (valid || isLt2M) {
              let compressNum = file.width - that.defaultWidth
              if (compressNum >= 100) {
                imageConversion
                  .compressAccurately(file, {
                    size: ((file.size * (that.defaultWidth / file.width)) / 1000).toFixed(0),
                    width: that.defaultWidth
                  })
                  .then(res => {
                    //修改dpi
                    changeDpiBlob(res, that.defaultDpi).then(blob => {
                      let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                      setTimeout(() => {
                        that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                      }, 0)
                    })

                    resolve(res)
                  })
                  .catch(err => {
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    if (!that.fileList.length) {
                      that.loading = false
                    }
                    console.log('上传失败234')
                    that.$message.error(file.name + '上传失败')
                  })
              } else if (compressNum < 100 && compressNum >= 0) {
                imageConversion
                  .compressAccurately(file, {
                    size: ((file.size * 0.9) / 1000).toFixed(0),
                    width: that.defaultWidth
                  })
                  .then(res => {
                    //修改dpi
                    changeDpiBlob(res, that.defaultDpi).then(blob => {
                      let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                      setTimeout(() => {
                        that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                      }, 0)
                    })

                    resolve(res)
                  })
                  .catch(err => {
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    if (!that.fileList.length) {
                      that.loading = false
                    }
                    console.log('上传失败456')
                    that.$message.error(file.name + '上传失败')
                  })
              } else {
                imageConversion
                  .compressAccurately(file, {
                    size: ((file.size * 0.9) / 1000).toFixed(0),
                    width: file.width
                  })
                  .then(res => {
                    //修改dpi
                    changeDpiBlob(res, that.defaultDpi).then(blob => {
                      let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                      setTimeout(() => {
                        that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                      }, 0)
                    })

                    resolve(res)
                  })
                  .catch(err => {
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    if (!that.fileList.length) {
                      that.loading = false
                    }
                    console.log('上传失败456')
                    that.$message.error(file.name + '上传失败')
                  })
              }
            } else resolve(file)
          }
          // 需要把图片赋值
          img.src = _URL.createObjectURL(file)
        })
      } else {
        that.$message.error(file.name + '，分辨率错误')
        that.fileList.splice(
          that.fileList.findIndex(item => item.name == file.name),
          1
        )
        that.changeProgress(true)
        if (!that.fileList.length) {
          that.endProgress()
          that.loading = false
        }
        that.avatarLoading = false
        that.spinning = false
      }
    },
    async psdUpload(psdFile) {
      let that = this
      let fileSize = psdFile.size
      var url = URL.createObjectURL(psdFile)
      const psd = await PSD.fromURL(url)
      // 图片不大于4m,宽度不大于2000
      let file = this.base64ToFile(psd.image.toBase64().replace('png', that.upType), psdFile.name)
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        // 这里需要计算出图片的长宽
        let img = new Image()
        img.onload = function() {
          file.width = img.width // 获取到width放在了file属性上
          file.height = img.height // 获取到height放在了file属性上
          // console.log(11, file)
          if (!that.sizeList.length) {
            this.$message.error('未获取到默认尺寸，无法上传图片')
            return
          }
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          let compressNum = file.width - that.defaultWidth
          if (compressNum >= 100) {
            const promise = imageConversion.compressAccurately(file, {
              size: ((file.size * (that.defaultWidth / file.width)) / 1000).toFixed(0),
              width: that.defaultWidth
            })
            that.psdPromise.push({ promise, name: file.name })
            if (that.psdPromise.length == that.psdFiles.length) {
              that.allUpload()
            }
          } else if (compressNum < 100 && compressNum >= 0) {
            const promise = imageConversion.compressAccurately(file, {
              size: ((file.size * 0.9) / 1000).toFixed(0),
              width: that.defaultWidth
            })
            that.psdPromise.push({ promise, name: file.name })
            if (that.psdPromise.length == that.psdFiles.length) {
              that.allUpload()
            }
          } else {
            const promise = imageConversion.compressAccurately(file, {
              size: ((file.size * 0.9) / 1000).toFixed(0),
              width: file.width
            })
            that.psdPromise.push({ promise, name: file.name })
            if (that.psdPromise.length == that.psdFiles.length) {
              that.allUpload()
            }
          }
        }
        // 需要把图片赋值
        img.src = _URL.createObjectURL(file)
      })
    },
    allUpload() {
      let that = this
      Promise.all(this.psdPromise.map(item => item.promise))
        .then(rest => {
          rest.forEach((res, index) => {
            changeDpiBlob(res, that.defaultDpi).then(blob => {
              let name = that.psdPromise[index].name
              let newFile = new File([blob], name, { type: 'contentType', lastModified: Date.now() })
              setTimeout(() => {
                that.upLoadEnd(newFile, that.upType, that.btnType, that.psdFiles.find(item => item.name == name).size)
              }, 0)
            })
          })
        })
        .catch(err => {
          that.fileList.splice(
            that.fileList.findIndex(item => item.name == file.name),
            1
          )
          if (!that.fileList.length) {
            that.loading = false
          }
          console.log('上传失败123')
          that.$message.error(file.name + '上传失败')
        })
    },
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bytes = atob(arr[1]) // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n)
      while (n--) {
        ia[n] = bytes.charCodeAt(n)
      }
      return new File([ia], fileName, { type: mime })
    },
    async upLoadEnd(file, type, btnType, fileSize) {
      let uploadPath = ''
      if (
        this.taskDetail.productionName &&
        this.taskDetail.chapterSequenceNo &&
        this.taskDetail.platformName &&
        this.taskDetail.taskName
      ) {
        uploadPath =
          this.taskDetail.platformName +
          '/' +
          this.taskDetail.productionName +
          '/' +
          this.taskDetail.chapterSequenceNo +
          '/' +
          this.taskDetail.taskName +
          '/' +
          this.getGuid()
      }
      if (!uploadPath) {
        uploadPath = this.getGuid()
      }
      console.log(uploadPath, '上传OSS平台作品阶段路径')
      this.tokenAxios({
        url: '/oss',
        method: 'get'
      })
        .then(async res => {
          let policy = {
            policy: res.data.policy,
            signature: res.data.signature,
            ossaccessKeyId: res.data.accessid,
            dir: res.data.dir,
            host: res.data.host,
            key: `${res.data.dir}glxt/scyg/psd/${this.userInfo.realname}/${uploadPath}/${file.name
              .replaceAll('+', '加')
              .replaceAll(' ', '')}`
          }
          this.ossUploadDatas[file.name] = policy
          this.ossFilePolicy[file.name] = policy
          let formData = new FormData()
          for (let key in this.ossUploadDatas[file.name]) {
            formData.append(key, this.ossUploadDatas[file.name][key])
          }
          formData.append('file', file)
          let that = this
          postAction(policy.host, formData)
            .then(res => {
              console.log('成功OSS', policy.host, formData)
              Object.assign(file, { status: 'done' })
              let params = this.getSaveFileParams(file)
              var img = new Image()
              img.src = params.filePath
              if (!that.sizeList.length) {
                this.$message.error('未获取到默认尺寸，无法上传图片')
                return
              }
              img.onload = async function() {
                /*                 if ((that.defaultType && that.defaultType.indexOf(type) == -1) || !that.defaultType) {
                  that.$message.error(file.name + '格式错误')
                  that.fileList.splice(
                    that.fileList.findIndex(item => item.name == file.name),
                    1
                  )
                  if (!that.fileList.length) {
                    that.loading = false
                  }
                  postAction('/oss/delete_oss_file', [params.filePath])
                  return
                } else if (!this.height) {
                  that.$message.error(file.name + '图片加载失败')
                  that.fileList.splice(
                    that.fileList.findIndex(item => item.name == file.name),
                    1
                  )
                  if (!that.fileList.length) {
                    that.loading = false
                  }
                  postAction('/oss/delete_oss_file', [params.filePath])
                } else if (
                  (this.height > that.defaultMaxHeight || this.height < that.defaultMinHeight) &&
                  !that.defaultHeight
                ) {
                  that.$message.error(file.name + '高度错误')
                  that.fileList.splice(
                    that.fileList.findIndex(item => item.name == file.name),
                    1
                  )
                  if (!that.fileList.length) {
                    
                  }
                  postAction('/oss/delete_oss_file', [params.filePath])
                  return
                } else if (that.defaultHeight && this.height != that.defaultHeight) {
                  that.$message.error(file.name + '高度错误')
                  that.fileList.splice(
                    that.fileList.findIndex(item => item.name == file.name),
                    1
                  )
                  if (!that.fileList.length) {
                    that.loading = false
                  }
                  postAction('/oss/delete_oss_file', [params.filePath])
                  return
                } else if (that.defaultWidth && this.width != that.defaultWidth) {
                  if (!that.fileList.length) {
                    that.loading = false
                  }
                  postAction('/oss/delete_oss_file', [params.filePath])
                  return
                } */
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.isOnly) {
                  if (that.changeFileList.length) {
                    let deleteIdx = -1
                    that.changeFileList.forEach((f, fIdx) => {
                      if (f.fileName == file.name) {
                        postAction('/oss/delete_oss_file', [f.filePath])
                        deleteIdx = fIdx
                      }
                    })
                    if (deleteIdx > -1) {
                      that.changeFileList.splice(deleteIdx, 1)
                    }
                  }
                  that.changeFileList.push({
                    fileName: file.name,
                    filePath: params.filePath,
                    dpi: that.defaultDpi,
                    height: this.height,
                    width: this.width,
                    fileLength: fileSize,
                    id: that.changeFileList.length + '-' + 'changeImg',
                    checked: false
                  })
                  if (!that.fileList.length) {
                    let arr = that.changeFileList.map(f => f.fileName)
                    postAction('/finish/manuscript/fileName/', arr).then(res => {
                      if (res.code == 200) {
                        arr = res.data
                        if (arr.length) {
                          let newFileList = []
                          arr.forEach(name => {
                            newFileList.push(that.changeFileList.find(f => f.fileName == name))
                          })
                          that.changeFileList = newFileList
                        } else {
                          that.changeFileList = that.changeFileList.sort(function compareFunction(a, b) {
                            return a.fileName.localeCompare(b.fileName)
                          })
                        }
                        that.loading = false
                        that.upList = []
                        that.$message.success('上传完成')
                      } else {
                        that.$message.error(res.msg)
                      }
                    })
                  }
                } else {
                  if (that.changeFileListOnly.length) {
                    let deleteIdx = -1
                    that.changeFileListOnly.forEach((f, fIdx) => {
                      if (f.fileName == file.name) {
                        postAction('/oss/delete_oss_file', [f.filePath])
                        deleteIdx = fIdx
                      }
                    })
                    if (deleteIdx > -1) {
                      that.changeFileListOnly.splice(deleteIdx, 1)
                    }
                  }
                  that.changeFileListOnly.push({
                    fileName: file.name,
                    filePath: params.filePath,
                    dpi: that.defaultDpi,
                    height: this.height,
                    width: this.width,
                    fileLength: fileSize,
                    id: that.changeFileListOnly.length + '-' + 'changeImg',
                    checked: false
                  })
                  if (!that.fileList.length) {
                    that.loading = false
                    that.upList = []
                    that.changeFileListOnly = that.changeFileListOnly.sort(function compareFunction(a, b) {
                      return a.fileName.localeCompare(b.fileName)
                    })
                    that.replaceOnly()
                  }
                }
              }
            })
            .catch(err => {
              console.log('失败OSS', policy.host, formData)
              that.fileList.splice(
                that.fileList.findIndex(item => item.name == file.name),
                1
              )
              if (!that.fileList.length) {
                that.loading = false
              }
              that.changeProgress(true)
              console.log('上传失败2')
              that.$message.error(file.name + '上传OSS失败,请查看文件是否重名后联系管理员。')
            })
        })
        .catch(err => {
          this.$message.error('获取OSS签名失败')
          console.log(err)
          this.loading = false
        })
    },
    changeAll() {
      this.visible = !this.visible
      /*       if (this.changeFileList.length) {
        this.changeFileList.forEach(item => {
          if(item.filePath){
            postAction('/oss/delete_oss_file', [item.filePath])
          }
        })
      }
      this.changeFileList = [] */
    },
    viewClick(item) {
      let index = this.rightDataSource.findIndex(i => i.id == item.id)
      window.open(
        `/fatchStraw/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/${item.id}${
          item.wasLock ? '?loadLock=true' : ''
        }`
      )
    },
    async deleteJpgImg() {
      if (this.selectionJpgRows.some(item => item.hasAnnotation) && !this.isAdmin) {
        return this.$message.error('文件包含批注内容，无法删除')
      }
      this.$confirm({
        title: '确认删除当前版本文件？删除后文件会自动回滚至上一个版本',
        onOk: async () => {
          const res = await deleteAction('/finish/manuscript?ids=' + this.selectedJpgRowKeys.join(','))
          if (res.code == 200) {
            this.$message.success('删除成功')
            this.selectionJpgRows.forEach(item => {
              postAction('/oss/delete_oss_file', [item.filePath])
            })
            this.getRightTableData()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async batchLock(wasLock) {
      let arr = []
      this.selectionJpgRows.forEach(item => {
        arr.push({ ...item, wasLock })
      })
      const res = await putAction('/finish/manuscript/batch/lock', arr)
      if (res.code == 200) {
        this.$message.success(!wasLock ? '解锁成功' : '锁定成功')
        this.getRightTableData()
      } else {
        this.$message.error(res.msg)
      }
    },
    async getRightTableData() {
      let params = {
        current: /* this.jpgPagination.current */ 1,
        size: /* this.jpgPagination.pageSize */ -1,
        chapterId: this.taskDetail.chapterId,
        fileType: 'JPG',
        taskId: this.taskDetail.taskId
      }
      this.jpgLoading = true
      const res = await postAction('/finish/manuscript/list', params)
      if (res.code == 200) {
        this.selectedJpgRowKeys = []
        this.selectionJpgRows = []
        this.rightDataSource = res.data.records
        this.rightDataSource.forEach((item, index) => {
          item.checked = false
          item.fileName = item.fileName.replaceAll('<br>', '\n')
          item.showCheck = false
          item.index = index
        })
        this.jpgLoading = false
        this.replaceOnlyId = null
        this.replaceDom = null
        this.isOnly = false
        this.dragChangeValue = null
        this.defaultHeight = 0
      }
      this.getFileLength()
    },
    startProgress(fileList, finishFileList, upType) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        this.rightShow = true
        if (this.rightNum < 80) return
        this.rightTransNum = 1
        this.rightNum = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      } else {
        this.leftShow = true
        if (this.leftNum < 80) return
        this.leftTransNum = 1
        this.leftNum = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      }
      /* this.disUpload = true */
    },
    changeProgress(fileList, finishFileList, upType) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        if (this.rightNum < 80) return
        this.rightNum = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      } else {
        if (this.leftNum < 80) return
        this.leftNum = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      }
    },
    changeTransProgress(fileList, upType) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        this.rightNum = ((this.rightTransNum / fileList.length) * 80).toFixed(0) - 0
        this.rightTransNum++
        if (this.rightNum > 78) {
          this.rightNum = 80
        }
      } else {
        this.leftNum = ((this.leftTransNum / fileList.length) * 80).toFixed(0) - 0
        this.leftTransNum++
        if (this.leftNum > 78) {
          this.leftNum = 80
        }
      }
    },
    endProgress(upType) {
      if (upType == 'JPG') {
        this.rightShow = false
        this.rightNum = 100
        setTimeout(() => {
          this.rightTransNum = 0
          this.rightNum = 0
        }, 500)
      } else {
        this.leftShow = false
        this.leftNum = 100
        setTimeout(() => {
          this.leftTransNum = 0
          this.leftNum = 0
        }, 500)
      }
      /* this.disUpload = false */
    },
    imgOnError(index) {
      if (this.rightDataSource[index]) this.rightDataSource[index].isLoad = true
      this.rightNum =
        (this.rightDataSource.filter(item => item.isLoad).length / this.fileRightList.length).toFixed(2) * 100 - 0
      if (this.rightNum == 100) {
        setTimeout(() => {
          this.rightShow = false
        }, 800)
      }
    },
    selectRightImg(item, idx) {
      /* if (item.wasLock) return */
      let index = this.rightDataSource.findIndex(r => r.id == item.id)
      if (this.rightDataSource[index].checked) {
        this.rightDataSource[index].checked = false
      } else {
        this.rightDataSource[index].checked = true
      }
      this.selectedJpgRowKeys = this.rightDataSource.filter(item => item.checked).map(item => item.id)
      this.selectionJpgRows = this.rightDataSource.filter(item => item.checked)
      this.$forceUpdate()
    },
    selectChangeImg(index) {
      if (this.changeFileList[index].checked) {
        this.changeFileList[index].checked = false
      } else {
        this.changeFileList[index].checked = true
      }
      this.selectedRightRowKeys = this.changeFileList.filter(item => item.checked).map(item => item.id)
      this.selectionRightRows = this.changeFileList.filter(item => item.checked)
      this.$forceUpdate()
    },
    beforeRightUpload(file, e) {
      if (this.viewType == 'list') this.jpgLoading = true
      file.checked = false
      file.isLoad = false
      this.fileRightList = [...this.fileRightList, file]

      this.fileRightList.sort((a, b) => {
        let result = a.name.match(/(\d+)[^0-9]*$/)[1]
        let resultB = b.name.match(/(\d+)[^0-9]*$/)[1]
        return result - resultB
      })
      return false
    },
    handleRightChange(info) {
      this.rightShow = true
      this.rightArr.push(info)
      this.rightDataSource = []
      if (this.rightArr.length == info.fileList.length) {
        this.rightArr.forEach((item, i) => {
          this.fileRightList.forEach((data, index) => {
            if (i == index) {
              const url = window.URL ? window.URL.createObjectURL(data) : window.webkitURL.createObjectURL(data)
              item.url = url
              let reader = new FileReader()
              reader.readAsDataURL(data)
              reader.onload = async () => {
                const image = new Image()
                image.src = reader.result
                image.onload = () => {
                  item.imgWidth = image.width
                  item.imgHeight = image.height
                  let obj = {}
                  let imgSize = (item.file.size / 1024 / 1024).toFixed(2) + 'M'
                  obj = {
                    cc: item.imgWidth + '*' + item.imgHeight,
                    fileSize: imgSize,
                    id: item.file.name,
                    fileName: item.file.name
                  }
                  this.rightDataSource.push({ ...obj, sort: this.rightDataSource.length + 1 })
                  if (this.rightDataSource.length == this.fileRightList.length) {
                    this.jpgLoading = false
                  }
                }
              }
            }
          })
        })
        this.imgRights = []
        this.rightArr.forEach(item => {
          this.imgRights.push(item.url)
        })
      }
    },
    selectLeftImg(index) {
      if (this.fileLeftList[index].checked) {
        this.fileLeftList[index].checked = false
      } else {
        this.fileLeftList[index].checked = true
      }
      this.$forceUpdate()
    },
    beforeLeftUpload(file, e) {
      this.psdLoading = true
      file.checked = false
      this.fileLeftList = [...this.fileLeftList, file]
      this.fileLeftList.sort((a, b) => {
        let result = a.name.match(/(\d+)[^0-9]*$/)[1]
        let resultB = b.name.match(/(\d+)[^0-9]*$/)[1]
        return result - resultB
      })
      return false
    },
    handleLeftChange(info) {
      this.leftShow = true
      this.leftArr.push(info)
      this.leftDataSource = []
      if (this.leftArr.length == info.fileList.length) {
        this.leftArr.forEach((item, i) => {
          this.fileLeftList.forEach((data, index) => {
            if (i == index) {
              const url = window.URL ? window.URL.createObjectURL(data) : window.webkitURL.createObjectURL(data)
              item.url = url
              let reader = new FileReader()
              reader.readAsDataURL(data)
              reader.onload = async () => {
                const image = new Image()
                image.src = reader.result
                image.onload = () => {
                  item.imgWidth = image.width
                  item.imgHeight = image.height
                  let obj = {}
                  let imgSize = (item.file.size / 1024 / 1024).toFixed(2) + 'M'
                  obj[item.file.name] = { imgWidth: item.imgWidth, imgHeight: item.imgHeight, size: imgSize }
                  this.imgSizeLeftList = Object.assign({}, this.imgSizeLeftList, obj)
                }
              }
            }
          })
        })
        this.imgLefts = []
        this.leftArr.forEach(item => {
          this.imgLefts.push(item.url)
        })
        this.psdLoading = false
      }
    },
    onPsdSelectChange(selectedRowKeys, selectionRows) {
      // console.log(selectedRowKeys,selectionRows,"onSelectChange")
      this.selectedPsdRowKeys = selectedRowKeys
      this.selectionPsdRows = selectionRows
    },
    onJpgSelectChange(selectedRowKeys, selectionRows) {
      // console.log(selectedRowKeys,selectionRows,"onSelectChange")
      this.selectedJpgRowKeys = selectedRowKeys
      this.selectionJpgRows = selectionRows
      this.rightDataSource.forEach(item => {
        if (this.selectedJpgRowKeys.some(key => key == item.id)) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    onRightSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRightRowKeys = selectedRowKeys
      this.selectionRightRows = selectionRows
      this.changeFileList.forEach(item => {
        if (this.selectedRightRowKeys.some(key => key == item.id)) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    handlePsdTableChange(pagination) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      this.psdPagination = pagination
      this.loadPsdData()
    },
    handleJpgTableChange(pagination) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      this.jpgPagination = pagination
    },
    reloadData() {
      this.getAlldata()
    },
    getAlldata() {
      this.getTask()
    },
    skip() {
      window.history.go(-1)
    },
    async getTaskList() {
      const res = await getAction('/tasks/taskChapters', { taskId: this.taskDetail.taskId })
      try {
        if (res.code == 200) {
          this.taskList = res.data
        } else {
          this.message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err)
      }
    },
    async getFileLength() {
      const res = await postAction('/finish/manuscript/sum/file/length', [this.taskDetail.chapterId])
      if (res.code == 200) {
        this.sectionTotalSize = res.data[this.taskDetail.chapterId] - 0 || 0
      }
    },
    async getTask() {
      this.spinning = true
      const res = await getAction('tasks/get/' + this.params.taskId)
      try {
        this.taskDetail = res.data
        this.taskDetail.showName = this.taskDetail.productionName || this.taskDetail.foreignName
        this.spinning = false
        this.getSizeList()
        this.getTaskList()
        this.getRightTableData()
      } catch (err) {
        console.log(err)
        this.spinning = false
      }
    },
    async getSizeList() {
      const res = await getAction(
        '/kt/translate/manuscript/specification?' +
          new URLSearchParams(handleArrayParams({ productIds: [this.taskDetail.productionId] })).toString()
      )
      if (res.code == 200) {
        this.sizeList = res.data
        if (!this.sizeList.length) {
          this.$message.error('未获取到默认尺寸，无法上传图片')
        } else {
          this.defaultMaxHeight = this.sizeList[0].size.jpgMaxHeight
          this.defaultMinHeight = this.sizeList[0].size.jpgMinHeight
          this.defaultWidth = this.sizeList[0].size.jpgWidth
          this.defaultType = this.sizeList[0].size.jpgFormat
          this.defaultDpi = this.sizeList[0].size.jpgResolution
          if (this.$refs.defaultSizeUpload) {
            this.$refs.defaultSizeUpload.defaultType = this.defaultType
          }
          if (this.$refs.defaultSizeUploadjpg) {
            this.$refs.defaultSizeUploadjpg.defaultType = this.defaultType
          }
          if (this.$refs.defaultSizeUploadTmb) {
            this.$refs.defaultSizeUploadTmb.defaultType = this.sizeList[0].size.tmbFormat
          }
        }
      } else {
        this.$message.error('未获取到默认尺寸，无法上传图片')
      }
    },
    changeViewType(viewType) {
      this.viewType = viewType
      this.selectedPsdRowKeys = []
      this.selectionPsdRows = []
      this.selectedJpgRowKeys = []
      this.selectionJpgRows = []
      this.rightDataSource.forEach(item => {
        item.checked = false
      })
      if (sessionStorage.getItem('submit_left_sort')) {
        let sortData = JSON.parse(sessionStorage.getItem('submit_left_sort'))
        this.columns.forEach(c => {
          if (c.dataIndex == sortData.columnKey) {
            c.defaultSortOrder = sortData.order
          }
        })
      }
      if (sessionStorage.getItem('submit_right_sort')) {
        let sortData = JSON.parse(sessionStorage.getItem('submit_right_sort'))
        this.columnsRight.forEach(c => {
          if (c.dataIndex == sortData.columnKey) {
            c.defaultSortOrder = sortData.order
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.upload-title {
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  top: 60px;
  left: -21px;
  padding-top: 10px;
  padding-left: 20px;
}
/* /deep/ .have-data .ant-table-body {
  height: calc(-460px + 100vh) !important;
} */
.select-num {
  position: absolute;
  bottom: 5px;
  line-height: 32px;
  left: 20px;
}
.not-allowed {
  cursor: not-allowed;
}
.img-count {
  position: absolute;
  top: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 2px 6px;
  font-size: 12px;
  color: #fff;
}
/deep/ .ant-upload {
  padding: 0 !important;
}
/deep/ .ant-spin-container {
  height: 100% !important;
}
.was-lock-img {
  opacity: 0.5;
}
.upload-list {
  height: 100%;
  > div {
    display: flex;
    flex-wrap: wrap;
  }
  .img-div {
    width: 110px;
    padding-top: 30px;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    flex-direction: column;
    img {
      box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.3s ease-in-out;
    }
    .select-img {
      width: 100%;
      height: 100%;
      background-color: var(--theme-color);
      top: -3px;
      position: absolute;
      opacity: 0.2;
      &:hover {
        + .img-action {
          opacity: 1;
          z-index: 1;
        }
      }
    }
    .no-select-img {
      width: 100%;
      height: 100%;
      top: -3px;
      position: absolute;
      &:hover {
        + .img-action {
          opacity: 1;
          z-index: 1;
        }
      }
    }
    .img-name {
      width: 110px;
      word-wrap: break-word;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
      white-space: pre-line;
    }
  }
  .img-div-height {
    text-align: center;
    padding-top: 30px;
    margin-top: 10px;
    margin-left: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    flex-direction: column;
    img {
      box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.3s ease-in-out;
      max-width: 140px;
    }
    .select-img {
      width: 100%;
      height: 100%;
      background-color: var(--theme-color);
      top: -3px;
      position: absolute;
      opacity: 0.2;
      &:hover {
        + .img-action {
          opacity: 1;
        }
      }
    }
    .no-select-img {
      width: 100%;
      height: 100%;
      top: -3px;
      position: absolute;
      &:hover {
        + .img-action {
          opacity: 1;
        }
      }
    }
    .img-name {
      width: 100%;
      word-wrap: break-word;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
      white-space: pre-line;
    }
  }
}
/deep/ .ant-upload-drag {
  background-color: #fff !important;
  cursor: default !important;
  border: none;
}
.check-icon:hover {
  color: var(--theme-color);
}
.check-icon {
  transition: 0.3s all;
  font-size: 16px;
}
.img-action {
  width: 60px;
  position: absolute;
  top: 0;
  left: calc(50% - 29px);
  display: flex;
  justify-content: space-between;
  text-align: center;
  line-height: 35px;
  opacity: 0;
  transition: 0.3s all;
  z-index: 1;
  &:hover {
    opacity: 1;
  }
}
.img-action {
  cursor: pointer;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
  .img-div {
    width: 110px;
    padding-top: 30px;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    flex-direction: column;
    img {
      box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.3s ease-in-out;
    }
    .select-img {
      width: 100%;
      height: 100%;
      background-color: var(--theme-color);
      top: -3px;
      position: absolute;
      opacity: 0.2;
      &:hover {
        + .img-action {
          opacity: 1;
          z-index: 1;
        }
      }
    }
    .no-select-img {
      width: 100%;
      height: 100%;
      top: -3px;
      position: absolute;
      &:hover {
        + .img-action {
          opacity: 1;
          z-index: 1;
        }
      }
    }
    .img-name {
      width: 110px;
      padding-top: 15px;
      word-wrap: break-word;
      padding-bottom: 15px;
      text-align: center;
      white-space: pre-line;
    }
  }
  .img-div-height {
    text-align: center;
    padding-top: 30px;
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-width: 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    flex-direction: column;
    img {
      box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.3s ease-in-out;
      width: auto;
    }
    .select-img {
      width: 100%;
      height: 100%;
      background-color: var(--theme-color);
      top: -3px;
      position: absolute;
      opacity: 0.2;
      &:hover {
        + .img-action {
          opacity: 1;
        }
      }
    }
    .no-select-img {
      width: 100%;
      height: 100%;
      top: -3px;
      position: absolute;
      &:hover {
        + .img-action {
          opacity: 1;
        }
      }
    }
    .img-name {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      word-wrap: break-word;
      text-align: center;
      white-space: pre-line;
    }
  }
}
/deep/ .ant-table-row-cell-break-word {
  padding: 6px !important;
  border: none !important;
}
.prog-div {
  position: absolute;
  height: calc(100vh - 415px);
  top: -5px;
  /* background-color: rgba(0, 0, 0, 0.25); */
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-task {
  padding-top: 5vh;
  padding-bottom: 2vh;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  position: relative;
  overflow: hidden;
  height: max-content;
}
.left,
.right {
  position: absolute;
  width: 2vw;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.35);
    opacity: 0;
    cursor: default;
  }
  i {
    color: rgba(0, 0, 0, 0.1);
  }
  &:hover {
    i {
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        ~ span {
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }
      }
    }
  }
}
.box {
  user-select: none;
  position: relative;

  .mask {
    position: absolute;
    background: #409eff;
    opacity: 0.4;
    z-index: 999;
    transition: none !important;
  }
  .mask_two {
    position: absolute;
    background: #409eff;
    opacity: 0.4;
    z-index: 999;
    transition: none !important;
  }
}
.left {
  left: 10px;
}
.right {
  right: 10px;
}
.content {
  padding-top: 40px;
}
.left-button-list {
  width: 150px;
}
.right-button-list {
  float: right;
  display: flex;
}
.left-content {
  .left-content-button-list {
    height: 32px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .left-content-list {
    margin-top: 25px;
    /* padding: 25px; */
    height: calc(100vh - 375px);
    transition: 0.3s all;
    overflow: auto;
    position: relative;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &:hover {
      border: 1px solid var(--theme-color);
    }
  }
}
.ghost {
  opacity: 0 !important;
}
.right-content {
  padding-left: 50px;
  .left-content-button-list {
    height: 32px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .left-content-list {
    margin-top: 25px;
    height: calc(100vh - 375px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.3s all;
    &:hover {
      border: 1px solid var(--theme-color);
    }
  }
}
.left-content-list::-webkit-scrollbar-button {
  height: 0;
}
.left-content-list::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}
.left-content-list::-webkit-scrollbar-track {
  border-radius: 0;
}
.left-content-list::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
.left-content-list::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
/deep/ .ant-table-row:hover {
  .ant-table-row-cell-break-word {
    background-color: var(--back-color) !important;
  }
}
/deep/ .ant-table-placeholder {
  border-bottom: none !important;
}
/deep/ .ant-upload.ant-upload-drag {
  border: none !important;
}
/deep/ .ant-upload-drag-container {
  display: block !important;
}
</style>
