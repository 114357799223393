<template>
  <a-modal
    class="video-modal"
    :width="windowWidth"
    :visible="visible"
    :footer="null"
    :zIndex="9999"
    :maskClosable="false"
    :closable="false"
    :dialogStyle="{ top: '20px' }"
    @cancel="handleCancel"
  >
    <div class="video-content" :style="backCss">
      <div
        :style="{
          maxWidth: windowWidth+'px',
          maxHeight: windowHeight - 90 + 'px',
          overflowY: 'auto',
          overflowX: 'hidden'
        }"
        class="img-content-modal"
        @scroll="changeScroll"
      >
        <div v-for="(img, index) in urls" :key="index" :style="{ display: 'flex', justifyContent: 'center' }">
          <img
            class="video-image"
            :style="{ userSelect: 'none', transform: `rotate(${angle}deg)`, maxWidth: width, height: 'auto' }"
            @click.stop
            :src="img.url"
            alt=""
          />
        </div>
      </div>

      <div v-if="actionShow" class="image-action" @click.stop>
        <div><a-icon type="zoom-in" @click="scale('amp')" /></div>
        <div><a-icon type="zoom-out" @click="scale('reduce')" /></div>
        <!--         <div><a-icon style="transform: rotate(90deg);" @click="rorate('left')" type="undo" /></div>
        <div><a-icon style="transform: rotate(270deg);" @click="rorate('right')" type="redo" /></div> -->
      </div>
      <div v-if="actionShow" class="image-close" @click="handleCancel">
        <a-icon type="close" />
      </div>
      <div v-if="actionShow" class="image-selection">
        <div
          @click="selectImg(index)"
          v-for="(img, index) in urls"
          :class="img.selected ? 'image-selected' : ''"
          :key="index + 'name'"
        >
          {{ img.name }}
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      width: '1000px',
      urls: [],
      imgShow: false,
      videoShow: false,
      actionShow: false,
      color: '',
      angle: 0,
      windowHeight: '0px',
      windowWidth: '0px'
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color
      }
    }
  },
  created() {
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
      this.$nextTick(() => {
        let doms = Array.from(document.getElementsByClassName('video-image'))
        doms?.forEach((dom, index) => {
          this.urls[index].height = dom.height
        })
      })
      this.$forceUpdate()
    })
  },
  methods: {
    scale(type) {
      if (type == 'amp') {
        let doms = Array.from(document.getElementsByClassName('video-image'))
        doms.forEach(dom => {
          dom.style.width = dom.clientWidth + 10 + 'px'
        })
        if (doms[0].clientWidth-0 + 10 > 1000) {
          this.width = doms[0].clientWidth + 10 + 'px'
          doms.forEach(dom => {
            dom.style.width = dom.clientWidth + 10 + 'px'
          })
        }
        this.$nextTick(() => {
          doms.forEach((dom, index) => {
            this.urls[index].height = dom.height
          })
        })
      } else {
        let doms = Array.from(document.getElementsByClassName('video-image'))
        doms.forEach(dom => {
          if (dom.clientWidth > 50) {
            dom.style.width = dom.clientWidth - 10 + 'px'
          }
        })
        this.$nextTick(() => {
          doms.forEach((dom, index) => {
            this.urls[index].height = dom.height
          })
        })
      }
    },
    open(urls) {
      this.urls = urls
      this.visible = true
      console.log('开始加载')
      let loadImgs = []
      this.$nextTick(() => {
        let doms = Array.from(document.getElementsByClassName('video-image'))
        let that = this
        doms.forEach(dom => {
          dom.onload = function() {
            loadImgs.push(dom)
            if (loadImgs.length == doms.length) {
              that.allUpload()
            }
          }
        })
      })
    },
    selectImg(idx) {
      let tops = []
      this.urls.forEach((img, index) => {
        if (!index) {
          tops.push(0)
        } else {
          let num = 0
          for (let i = 0; i < index; i++) {
            num += this.urls[i].height
          }
          tops.push(num)
        }
      })
      document.getElementsByClassName('img-content-modal')[0].scrollTop = tops[idx]
    },
    changeScroll(e) {
      let tops = []
      this.urls.forEach((img, index) => {
        if (!index) {
          tops.push(0)
        } else {
          let num = 0
          for (let i = 0; i < index; i++) {
            num += this.urls[i].height
          }
          tops.push(num)
        }
      })
      let idx = 0
      tops.forEach((top, index) => {
        if (e.target.scrollTop >= top) {
          idx = index
        }
      })
      this.urls.forEach((img, index) => {
        if (index == idx) {
          img.selected = true
        } else {
          img.selected = false
        }
      })
    },
    allUpload() {
      let doms = Array.from(document.getElementsByClassName('video-image'))
      doms.forEach((dom, index) => {
        this.urls[index].height = dom.height
      })
      let selectIndex = this.urls.findIndex(img => img.selected)
      if (selectIndex) {
        document.getElementsByClassName('img-content-modal')[0].scrollTop = this.urls[selectIndex - 1].height
      }
      const timeOut = setTimeout(() => {
        this.actionShow = true
        clearTimeout(timeOut)
      }, 400)
    },
    rorate(type) {
      if (type == 'left') {
        this.angle = this.angle - 90
      } else {
        this.angle = this.angle + 90
      }
    },
    handleCancel() {
      this.urls = []
      this.actionShow = false
      this.visible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped lang="less">
.img-content-modal {
  &::-webkit-scrollbar-button {
    height: 0;
  }
  &::-webkit-scrollbar {
    border-radius: 0;
    position: relative;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #fff;
  }
  &::-webkit-scrollbar-track-piece {
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #c1c1c1;
  }
}
.video-content {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  min-height: 600px;
  padding: 0 60px;
  /* position: relative; */
  .image-action {
    position: fixed;
    bottom: 20px;
    height: 40px;
    width: 140px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    line-height: 40px;
    font-size: 16px;
    justify-content: space-between;
    color: #fff;
    > div {
      &:hover {
        > .anticon {
          transition: 0.3s all;
          cursor: pointer;
          color: var(--theme-color);
        }
      }
    }
  }
  .image-close {
    position: fixed;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .image-selection {
    background: rgba(0, 0, 0, 0.5);
    width: 180px;
    border-radius: 5px;
    position: fixed;
    top: 20px;
    left: 40px;
    padding: 12px;
    > div {
      line-height: 32px;
      transition: 0.3s all;
      color: #fff;
      text-align: center;
      &:hover {
        background: var(--theme-color);
        cursor: pointer;
      }
    }
    .image-selected {
      background: var(--theme-color);
    }
  }
}
</style>
